import { MemoryCacheStatistics, IMemoryCacheStatistics } from "./Microsoft.Extensions.Caching.Memory.MemoryCacheStatistics.gen";
import { CacheEntry, ICacheEntry } from "./CacheEntry.gen";

export interface ICacheDetails {
	stats: IMemoryCacheStatistics | undefined;

	keys: ICacheEntry[] | undefined;

	$type?: string | undefined;
}
export class CacheDetails {
	constructor(data?: ICacheDetails) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.stats) { this.stats = new MemoryCacheStatistics(data.stats); }
		this.keys = data.keys ? data.keys.map((k: any) => new CacheEntry(k)) : [];
	}

	stats: MemoryCacheStatistics | undefined;

	keys: CacheEntry[] | undefined = [];

	static $type: string = "CoreApi.Models.CacheDetails, ";
	$type?: string | undefined = CacheDetails.$type;
}

