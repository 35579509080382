/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get } from "./api_client";
import { ErrorSummary, IErrorSummary, ServerErrorSummary, IServerErrorSummary } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getAllDeviceErrors(): ApiResult<ErrorSummary[]> {
	return get<IErrorSummary[]>(`/api/error/device`).then(result => result === undefined ? undefined : result.map(o => new ErrorSummary(o)));
}

export async function getDeviceErrors(id?: string): ApiResult<ErrorSummary[]> {
	return get<IErrorSummary[]>(`/api/error/device/${ id }`).then(result => result === undefined ? undefined : result.map(o => new ErrorSummary(o)));
}

export async function getServerErrors(page: number, pageSize: number): ApiResult<ServerErrorSummary[]> {
		const queryParams = [page !== undefined ? `page=${page}` : undefined,
			pageSize !== undefined ? `pageSize=${pageSize}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IServerErrorSummary[]>(`/api/error/server${query}`).then(result => result === undefined ? undefined : result.map(o => new ServerErrorSummary(o)));
}
