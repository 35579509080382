import { DetectionZoneOccupancy, Facility } from "@app/shared";
import { getTargetTypeDisplayName } from "@app/shared/src/models/Shared.TargetType.gen";
import { formatInTimeZone } from "date-fns-tz";
import React, { useMemo } from "react";
import { CheckboxInput, SortableTable } from "../../shared";

type RecentOccupancyTableProps = {
    facility: Facility;
    data: DetectionZoneOccupancy[];
    isLoading?: boolean;
};

export const RecentOccupancyTable: React.FC<RecentOccupancyTableProps> = ({ facility, data, isLoading }) => {
    const getDeviceNameForZone = (zone?: DetectionZoneOccupancy) => facility.devices?.find(d => d.id === zone?.deviceId)?.name ?? "";

    const zone = facility.localTimezoneId ?? Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
    const timeZone = useMemo(() => {
        return formatInTimeZone(new Date(), zone, "z");
    }, [zone]);

    const extendedData = useMemo(() => {
        if (!data?.length) { return [] };
        return data.map(datum => ({
            ...datum,
            zoneName: facility.detectionZones?.find(z => z.id === datum?.detectionZoneId)?.name ?? "",
            zoneNum: -1,
            deviceName: facility.devices?.find(d => d.id === datum.deviceId)?.name ?? ""
        }))
            .map(datum => {
                const match = datum.zoneName.match(/\d+/); // \d+ matches one or more digits
                if (match) {
                    datum.zoneNum = parseInt(match[0], 10);
                }
                return datum;
            });
    }, [data, facility.detectionZones, facility.devices]);
    const hasNums = extendedData.every(x => x.zoneNum > -1);
    return (
        <SortableTable
            data={extendedData}
            initialSortKey={hasNums ? "zoneNum" : "detectionZoneId"}
            initialSortDir="asc"
            isLoading={isLoading}
            columns={[
                { dataKey: "detectionZoneId", header: "Detection Zone", width: 100, renderFunc: (zi) => `${facility.detectionZones?.find(z => z.id === zi?.detectionZoneId)?.name}` },
                { dataKey: "zoneNum", header: "Detection Zone Number", width: 100, renderFunc: zi => hasNums ? zi?.zoneNum : null },
                { dataKey: "isOccupied", header: "Occupied", width: 100, renderFunc: (zi) => <CheckboxInput checked={zi?.isOccupied} readOnly={true} /> },
                { dataKey: "targetType", header: "Target Type", width: 150, renderFunc: (target) => getTargetTypeDisplayName(target!.targetType) },
                { header: "Detection Device", width: 175, renderFunc: getDeviceNameForZone },
                { dataKey: "dateDetected", header: `Last Change (${timeZone})`, width: 135, renderFunc: (target) => target?.dateDetected ? formatInTimeZone(target?.dateDetected, zone, "MMM d, HH:mm") : "" },
            ]}
        />
    );
}