/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, Length, MinLength } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IErrorDetailsEntity {
	hashId: string;

	details: string;

	stackTrace: string | undefined;

	$type?: string | undefined;
}
export class ErrorDetailsEntity {
	constructor(data?: IErrorDetailsEntity) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	@Length(0, 32)
	@IsNotEmpty()
	hashId: string = "";

	@IsNotEmpty()
	@MinLength(1)
	details: string = "";

	stackTrace: string | undefined;

	static $type: string = ".ErrorDetailsEntity, ";
	$type?: string | undefined = ErrorDetailsEntity.$type;
}

