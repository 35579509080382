import { AggregatedTargetAttribute, AggregationPeriod, CustomClaimValue, Facility, Reports, TrafficReportConfiguration } from "@app/shared";
import { addDays, subMinutes } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useFetch } from "../../../hooks";
import { AllTargetTypes } from "../../reports/reportHelpers";
import { IconLink, Icons, LoadingIndicator, PageContainer, Select } from "../../shared";
import { AuthorizeView } from "../../shared/AuthorizeView";
import { RecentTargetsChart } from "../../shared/charts";
import { HoverTooltip } from "../../shared/HoverTooltip";
import { formatInTimeZone } from "date-fns-tz";
import { useLocalStorage } from "react-use";

type FacilityTargetsDashboardProps = {
    facility: Facility;
};
export const FacilityTargetsDashboard: React.FC<FacilityTargetsDashboardProps> = ({ facility }) => {
    const [end, setEnd] = useState(new Date(Math.floor(subMinutes(new Date(), 5).getTime() / (5 * 60 * 1000)) * (5 * 60 * 1000)));//idea is to cheat and make sure we hit target aggs instead of targets on initial load.    
    const [chartDeviceId, setChartDeviceId] = useLocalStorage<string | null>(
        `facilitytargetdash-${facility.id}-device`,
        !!(facility.devices?.length) ? facility.devices![0].id! : null,
        {
            raw: false, // Ensures value is parsed as JSON
            serializer: JSON.stringify,
            deserializer: (value) => {
                const parsed = JSON.parse(value);
                return facility?.devices?.find(fd => fd.id === parsed)?.id ?? facility.devices?.[0].id ?? null; // Validate on load
            },
        }
    );
    const [recentTargets, errors, recentTargetHelpers] = useFetch(() => chartDeviceId ? Reports.getTotalVolume(AggregatedTargetAttribute.TargetType, AggregationPeriod.Day, { deviceIds: [chartDeviceId], inclusiveStart: addDays(new Date(), -14), exclusiveEnd: end, groupingValues: AllTargetTypes } as TrafficReportConfiguration) : Promise.resolve(undefined), [chartDeviceId, end]);//TODO: TT hard code here
    //When our facility changes, load the recent targets for the first device
    useEffect(() => {
        if (!chartDeviceId && (facility.devices?.length ?? 0) > 0) {
            setChartDeviceId(facility.devices![0].id);
        }
    }, [facility, chartDeviceId, setChartDeviceId]);
    const handleRefresh = () => {
        setEnd(new Date());
    }
    const hoverTip = useMemo(() => {
        return `Targets as of ${formatInTimeZone(end, facility.localTimezoneId ?? 'UTC', 'MM/dd/yyy HH:mm:ss')} (${facility.localTimezoneId ?? 'UTC'})`;
    }, [end, facility.localTimezoneId])

    return (

        <PageContainer title="Recent Targets" titleRight={
            <div className="flex items-center justify-center">
                <HoverTooltip hoverable={<IconLink className="mt-2" Icon={Icons.Refresh} iconSize={25} onClick={handleRefresh} />}>{hoverTip}</HoverTooltip>
                <Select options={facility.devices?.map(d => ({ label: d.name, value: d.id }))} value={chartDeviceId ?? undefined} onChange={e => setChartDeviceId(e.target.value)} />
            </div>
        }>
            {recentTargetHelpers.isLoading && <LoadingIndicator isLoading={recentTargetHelpers.isLoading} />
            }
            {(recentTargets && !errors && !recentTargetHelpers.isLoading && recentTargets.length > 0) &&
                <div className="w-full max-h-96 ">
                    <RecentTargetsChart data={recentTargets} facility={facility} device={facility.devices?.find(x => x.id === chartDeviceId)!} height={300} margin={{ top: 10, right: 60, bottom: 50, left: 60 }} />
                </div>
            }
            {(recentTargets && !errors && !recentTargetHelpers.isLoading && recentTargets.length === 0) &&
                <div className="flex justify-center ">
                    <p className="text-1xl brand-primary-lightened">No recent targets</p>
                </div>
            }

            {errors && !recentTargetHelpers.isLoading &&
                <HoverTooltip placement="auto" hoverable={
                    <p className="text-error-icon flex gap-3 place-items-center">
                        <Icons.Warning /> There was an error loading recent targets for this device.
                    </p>
                }>
                    <AuthorizeView claim={CustomClaimValue.SuperAdmin}><span>{errors}</span></AuthorizeView>
                </HoverTooltip>
            }
        </PageContainer>

    );
}