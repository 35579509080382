import { Facility, FacilityEvents, FacilityEventType, getFacilityEventTypeDisplayName, Target, Targets, UserError } from "@app/shared";
import { formatInTimeZone } from "date-fns-tz";
import { toastWarning } from "../../helpers";
import { useDialog, useFetch } from "../../hooks";
import { TargetDetailsDialog } from "../pages/facility/TargetDetailsDialog";
import { Link } from "./Link";
import { SortableTable } from "./SortableTable";

type FacilityEventsTableProps = {
    facility: Facility;
}

export const FacilityEventsTable: React.FC<FacilityEventsTableProps> = ({ facility }) => {
    const [events, , eventHelper] = useFetch(() => FacilityEvents.getByFacility(facility.id));
    const zone = facility.localTimezoneId ?? Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
    const details = useDialog<Target>();

    const handleViewTargetDetails = async (deviceId: string, externalTargetId: string) => {
        try {
            const targetWithHistory = await Targets.getTargetWithHistoryByExternalId(externalTargetId, deviceId);
            if (!targetWithHistory) {
                throw new UserError(["Failed to load target details.  Please try again later"]);
            }
            details.show(`Target Details`, "Okay", targetWithHistory, () => { });
        } catch (e) {
            toastWarning("Failed to load target details.  Please try again later");
            return;
        }
    };

    return <>
        <SortableTable
            columns={[
                {
                    header: "Date",
                    dataKey: "dateCreated",
                    width: 100,
                    renderFunc: (fe) => fe?.dateCreated ? formatInTimeZone(fe?.dateCreated, zone, "MM-dd p") : ""
                },
                {
                    header: "Event Type",
                    dataKey: "eventType",
                    width: 100,
                    renderFunc: (fe) => getFacilityEventTypeDisplayName(fe!.eventType)
                },
                {
                    header: "Device",
                    width: 100,
                    renderFunc: (fe) => facility.devices?.find(d => d.id === fe?.deviceId)?.name ?? ""
                },
                {
                    header: "Trigger",
                    width: 100,
                    renderFunc: (fe) => {
                        if (fe?.screenLineId)
                            return facility.screenLines?.find(sl => sl.id === fe?.screenLineId)?.name ?? "";
                        else if (fe?.detectionZoneId)
                            return facility.detectionZones?.find(dz => dz.id === fe?.detectionZoneId)?.name ?? "";
                    }
                },
                {
                    header: "Value",
                    width: 100,
                    renderFunc: (fe) => {
                        switch (fe?.eventType) {
                            case FacilityEventType.ScreenLineChange:
                                return fe?.eventValue > 1 ? "Entry" : "Exit";
                            case FacilityEventType.DetectionZoneChange:
                                return fe?.eventValue > 1 ? "Entry" : "Exit";
                            case FacilityEventType.DetectionZonePresence:
                                return (fe?.eventValue ? fe?.eventValue : "No") + " Target" + (fe?.eventValue !== 1 ? "s" : "");
                            case FacilityEventType.Speeding:
                                return ((fe?.eventValue ?? 0) * 2.23694) + " mph";
                            case FacilityEventType.DetectionZoneDwell:
                                return ">" + fe?.eventValue + " second" + (fe?.eventValue !== 1 ? "s" : "");
                            default:
                                return fe?.eventValue;
                        }
                    },
                    dataKey: "eventValue"
                },
                {
                    header: "",
                    width: 75,
                    renderFunc: (fe) => (fe?.deviceId && fe?.externalTargetId) ?
                        <Link onClick={() => handleViewTargetDetails(fe.deviceId!, fe.externalTargetId!)}>View Target</Link>
                        : ""
                }
            ]}
            initialSortDir="desc"
            data={events}
            isLoading={eventHelper.isLoading}
            emptyValue={"No Events were found, try configuring some event triggers under the endpoint tab"} />
        {details.renderDialog((val) =>
            <TargetDetailsDialog facility={facility} target={val} />
            , { closeOnOutsideClick: true, showCancelButton: false })}
    </>
}