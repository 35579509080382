import { Single, ISingle } from "./System.Single.gen";
import { WebRelayState } from "./WebRelayState.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IWebRelayConfig {
	relay: number;

	stateToSend: WebRelayState;

	expression: string;

	pulseTime: number | undefined;

	$type?: string | undefined;
}
export class WebRelayConfig {
	constructor(data?: IWebRelayConfig) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	relay: number = 0;

	stateToSend: WebRelayState = 0;

	@IsNotEmpty()
	expression: string = "";

	pulseTime: number | undefined;

	static $type: string = "Shared.Models.Api.V1.ClientEndpoints.WebRelayConfig, ";
	$type?: string | undefined = WebRelayConfig.$type;
}

