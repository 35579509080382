import { DateOptions, IConfiguredTrafficReport } from "./IConfiguredTrafficReport";
import { AggregationPeriod } from "@app/shared";
import { TotalVolumeDataLoader } from "./TotalVolumeDataLoader";

export const TotalVolumeByDay: IConfiguredTrafficReport = {
    name: "by Day",
    key: "tvd",
    description: "This report shows total traffic by day for the given range",
    component: (props) => (<TotalVolumeDataLoader {...props} aggregationPeriod={AggregationPeriod.Day} />),
    defaultChartType: 'line',
    defaultRange: DateOptions.ThisMonth,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};