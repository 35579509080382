/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, put, post, del } from "./api_client";
import { FirmwareVersionType } from "../models";
import { Device, IDevice, OptionValue, IOptionValue, ForwardedPortBase, IForwardedPortBase, DeviceCommand, IDeviceCommand } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getByOrganizationId(orgId?: string): ApiResult<Device[]> {
	return get<IDevice[]>(`/api/device/for-organization/${ orgId }`).then(result => result === undefined ? undefined : result.map(o => new Device(o)));
}

export async function getAllDevices(): ApiResult<Device[]> {
	return get<IDevice[]>(`/api/device`).then(result => result === undefined ? undefined : result.map(o => new Device(o)));
}

export async function getById(id?: string): ApiResult<Device> {
	return get<IDevice>(`/api/device/${ id }`).then(result => result === undefined ? undefined :  new Device(result));
}

export async function getOptions(orgId?: string, facilityId?: string): ApiResult<OptionValue[]> {
		const queryParams = [orgId !== undefined ? `orgId=${orgId}` : undefined,
			facilityId !== undefined ? `facilityId=${facilityId}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IOptionValue[]>(`/api/device/options${query}`).then(result => result === undefined ? undefined : result.map(o => new OptionValue(o)));
}

export async function updateHomographyPoints(id?: string, device?: Device): ApiResult<undefined> {
	return post(`/api/device/${ id }/update-homography-matrix`, device);
}

export async function resetHomographyPoints(id?: string): ApiResult<undefined> {
	return post(`/api/device/${ id }/reset-homography-matrix`);
}

export async function updateCameraAoiPoints(id?: string, device?: Device): ApiResult<undefined> {
	return post(`/api/device/${ id }/update-camera-aoi`, device);
}

export async function updateBoundaryPoints(id?: string, device?: Device): ApiResult<undefined> {
	return post(`/api/device/${ id }/update-boundaries`, device);
}

export async function updateDevice(id?: string, firmwareVersionType?: FirmwareVersionType): ApiResult<undefined> {
		const queryParams = [firmwareVersionType !== undefined ? `firmwareVersionType=${firmwareVersionType}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return post(`/api/device/${ id }/update-firmware${query}`);
}

export async function getByFacilityId(facilityId?: string): ApiResult<Device[]> {
	return get<IDevice[]>(`/api/device/by-facilityid/${ facilityId }`).then(result => result === undefined ? undefined : result.map(o => new Device(o)));
}

export async function associateDevice(deviceId?: string, facilityId?: string): ApiResult<undefined> {
	return post(`/api/device/${ deviceId }/associate-to-facility/${ facilityId }`);
}

export async function getUnassociatedDevice(deviceKey?: string): ApiResult<Device> {
	return get<IDevice>(`/api/device/unassociated/${ deviceKey }`).then(result => result === undefined ? undefined :  new Device(result));
}

export async function getFacilityDeviceOptions(facilityId?: string): ApiResult<OptionValue[]> {
	return get<IOptionValue[]>(`/api/device/device-options-for-facility/${ facilityId }`).then(result => result === undefined ? undefined : result.map(o => new OptionValue(o)));
}

export async function getUnassociatedDeviceOptions(): ApiResult<OptionValue[]> {
	return get<IOptionValue[]>(`/api/device/unassociated`).then(result => result === undefined ? undefined : result.map(o => new OptionValue(o)));
}

export async function requestSshAccess(deviceId?: string): ApiResult<undefined> {
	return get(`/api/device/requestssh/${ deviceId }`);
}

export async function getDeviceStream(deviceId?: string): ApiResult<string> {
	return get<string>(`/api/device/getdevicestream/${ deviceId }`);
}

export async function getForwardedPorts(deviceId?: string, max?: number, openOnly?: boolean): ApiResult<ForwardedPortBase[]> {
		const queryParams = [max !== undefined ? `max=${max}` : undefined,
			openOnly !== undefined ? `openOnly=${openOnly}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IForwardedPortBase[]>(`/api/device/${ deviceId }/forwardedports${query}`).then(result => result === undefined ? undefined : result.map(o => new ForwardedPortBase(o)));
}

export async function getCommands(deviceId?: string, max?: number, pendingOnly?: boolean): ApiResult<DeviceCommand[]> {
		const queryParams = [max !== undefined ? `max=${max}` : undefined,
			pendingOnly !== undefined ? `pendingOnly=${pendingOnly}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IDeviceCommand[]>(`/api/device/${ deviceId }/pending-commands${query}`).then(result => result === undefined ? undefined : result.map(o => new DeviceCommand(o)));
}

export async function deleteDevice(deviceId?: string): ApiResult<undefined> {
	return del(`/api/device/${ deviceId }`);
}

export async function changeFacility(deviceId?: string, newFacilityId?: string): ApiResult<undefined> {
	return put(`/api/device/${ deviceId }/facility/${ newFacilityId }`);
}

export async function save(device: Device): ApiResult<Device> {
	return put<IDevice>(`/api/device`, device).then(result => result === undefined ? undefined :  new Device(result));
}
