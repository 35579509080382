import { Facility, Vector2 } from "@app/shared";
import { useMemo } from 'react';

export interface ICoordsHelper {
    toLatLng: (offset: Vector2) => google.maps.LatLng,
    fromLatLng: (position: google.maps.LatLng | google.maps.LatLngLiteral) => Vector2
}

export function getLatLngConversions(position: google.maps.LatLng) {
    const latRad = position.lat() * Math.PI / 180;

    const metersPerDegreeLatitude = 111132.954 - (559.822 * Math.cos(2 * latRad) + 1.175 * Math.cos(4 * latRad));//https://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
    const metersPerDegreeLongitude = 111132.954 * Math.cos(latRad);
    return {
        metersPerDegreeLongitude,
        metersPerDegreeLatitude
    };
}


export function getFacilityCoordsHelper(facility: Facility): ICoordsHelper {
    const origin = facility.originReferencePointLatLng?.origin;
    /*if (!origin) {
        throw new Error('origin not defined');
    }*/

    const conversion = getLatLngConversions(new google.maps.LatLng({ lng: origin?.x ?? 0, lat: origin?.y ?? 0 }));

    function getCoordsFromOffset(offset: Vector2) {

        const xDeltaLng = (offset.x / conversion.metersPerDegreeLongitude);
        const yDeltaLat = (offset.y / conversion.metersPerDegreeLatitude);

        const x = (origin?.x ?? 0) + xDeltaLng;
        const y = (origin?.y ?? 0) + -1 * yDeltaLat;
        return new google.maps.LatLng({ lng: x, lat: y });
    }

    function getMetersFromOrigin(position: google.maps.LatLng | google.maps.LatLngLiteral) {
        const pos = typeof position.lat === 'function' ? (position as google.maps.LatLng).toJSON() : position as google.maps.LatLngLiteral;
        const lngDelta = pos.lng - (origin?.x ?? 0);
        const latDelta = pos.lat - (origin?.y ?? 0);
        return new Vector2({ x: lngDelta * conversion.metersPerDegreeLongitude, y: latDelta * -1 * conversion.metersPerDegreeLatitude });
    }

    return {
        fromLatLng: getMetersFromOrigin,
        toLatLng: getCoordsFromOffset
    }
}

export function useFacilityCoordsHelper(facility: Facility | undefined): ICoordsHelper {
    return useMemo(() => {
        if (!facility) { throw new Error('cannot create a coords helper without a facility') }
        return getFacilityCoordsHelper(facility);
    }, [facility]);
}