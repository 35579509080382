import { Int32, IInt32 } from "./System.Int32.gen";
import { OrientationSlim, IOrientationSlim } from "./OrientationSlim.gen";

export interface IHardwareStatusSlim {
	statusTimestamp: string | undefined;

	upTime: number;

	coreUsages: number[];

	orientation: IOrientationSlim | undefined;

	$type?: string | undefined;
}
export class HardwareStatusSlim {
	constructor(data?: IHardwareStatusSlim) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.statusTimestamp) { this.statusTimestamp = new Date(data.statusTimestamp); }
		if (data.orientation) { this.orientation = new OrientationSlim(data.orientation); }
	}

	statusTimestamp: Date | undefined = new Date();

	upTime: number = 0;

	coreUsages: number[] = [];

	orientation: OrientationSlim | undefined;

	static $type: string = "CoreApi.Controllers.HardwareStatusSlim, ";
	$type?: string | undefined = HardwareStatusSlim.$type;
}

