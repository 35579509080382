export interface ICacheEntry {
	key: string | undefined;

	serverName: string | undefined;

	isL2: boolean;

	$type?: string | undefined;
}
export class CacheEntry {
	constructor(data?: ICacheEntry) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	key: string | undefined;

	serverName: string | undefined;

	isL2: boolean = false;

	static $type: string = "CoreApi.Models.CacheEntry, ";
	$type?: string | undefined = CacheEntry.$type;
}

