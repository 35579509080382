import { CpuTempInfo, ICpuTempInfo } from "./View.Hardware.CpuTempInfo.gen";
import { PowerUsageInfo, IPowerUsageInfo } from "./View.Hardware.PowerUsageInfo.gen";
import { RamUsageInfo, IRamUsageInfo } from "./View.Hardware.RamUsageInfo.gen";
import { CpuUsageInfo, ICpuUsageInfo } from "./View.Hardware.CpuUsageInfo.gen";

export interface IHardwareStatusHistory {
	statusTimestamp: string | undefined;

	cpuTemps: ICpuTempInfo | undefined;

	powerUsage: IPowerUsageInfo | undefined;

	ramUsage: IRamUsageInfo | undefined;

	cpuUsage: ICpuUsageInfo | undefined;

	$type?: string | undefined;
}
export class HardwareStatusHistory {
	constructor(data?: IHardwareStatusHistory) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.statusTimestamp) { this.statusTimestamp = new Date(data.statusTimestamp); }
		if (data.cpuTemps) { this.cpuTemps = new CpuTempInfo(data.cpuTemps); }
		if (data.powerUsage) { this.powerUsage = new PowerUsageInfo(data.powerUsage); }
		if (data.ramUsage) { this.ramUsage = new RamUsageInfo(data.ramUsage); }
		if (data.cpuUsage) { this.cpuUsage = new CpuUsageInfo(data.cpuUsage); }
	}

	statusTimestamp: Date | undefined = new Date();

	cpuTemps: CpuTempInfo | undefined;

	powerUsage: PowerUsageInfo | undefined;

	ramUsage: RamUsageInfo | undefined;

	cpuUsage: CpuUsageInfo | undefined;

	static $type: string = "CoreApi.Models.View.Hardware.HardwareStatusHistory, ";
	$type?: string | undefined = HardwareStatusHistory.$type;
}

