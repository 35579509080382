import { Errors, useAuth } from "@app/shared";
import { useConfirmDialog, useFetch } from "../../../hooks";
import { DataInfoCard, Icons, Link, PageContainer, SortableTable } from "../../shared";
import { format } from "date-fns";
import { useMemo } from "react";

export const ServerErrorsTable: React.FC = () => {
    const [serverErrors, , serverErrorsHelpers] = useFetch(() => Errors.getServerErrors(1, 25));
    const { numberFormatter } = useAuth();
    const confirm = useConfirmDialog();
    const handleDetails = (stack: string) => {
        confirm.show("Stack Trace", stack, "OK", _ => { });
    }
    const mostRecent = useMemo(() => {
        if (!serverErrors?.length) return null;

        return serverErrors.reduce((latest, current) => {
            const currentDate = new Date(current.lastOccurrence!);
            return currentDate > new Date(latest.lastOccurrence!) ? current : latest;
        });
    }, [serverErrors]);
    const sumTotals = useMemo(() => {
        return serverErrors?.reduce(
            (acc, current) => {
                acc.total += current.total;
                acc.hourlyAverage += current.hourlyAverage;
                return acc;
            },
            { total: 0, hourlyAverage: 0 } // Initial sum values
        );
    }, [serverErrors]);
    return (
        <>
            {((serverErrors?.length ?? 0) > 0) && <div className="md:flex gap-4 flex">
                <DataInfoCard Icon={Icons.Filter} title="Last Error">{mostRecent ? format(mostRecent!.lastOccurrence!, 'MMM d  HH:mm') : ""}</DataInfoCard>
                <DataInfoCard Icon={Icons.Expand} title="Occurrences">{numberFormatter.format(sumTotals?.total ?? 0)}</DataInfoCard>
                <DataInfoCard Icon={Icons.Success} title="Hourly Rate">{numberFormatter.format(sumTotals?.hourlyAverage ?? 0)}</DataInfoCard>
            </div>}

            <PageContainer title="Recent Errors">
                <SortableTable isLoading={serverErrorsHelpers.isLoading} columns={[{
                    header: "Trace Id",
                    dataKey: "traceId",
                    width: 100,
                },
                {
                    header: "Last Occurrence",
                    dataKey: "lastOccurrence",
                    renderFunc: (data) => (<div className="w-100 break-words">{data?.lastOccurrence ? format(data?.lastOccurrence!, 'MMM d, yyyy HH:mm:ss') : ''}</div>),
                    width: 100,
                },
                {
                    header: "Errors per Hour",
                    dataKey: "hourlyAverage",
                    width: 100,
                },
                {
                    header: "Total",
                    dataKey: "total",
                    width: 100,
                }, {
                    header: "",
                    renderFunc: (fe) => (fe?.details?.length) ?
                        <Link onClick={() => handleDetails(fe.details!)}>View Details</Link>
                        : "",
                    width: 50,
                }]} data={serverErrors?.map(de => ({ ...de, id: de.traceId }))}
                    initialSortKey="lastOccurrence"
                    initialSortDir="desc" />
            </PageContainer>
            {confirm.renderDialog()}
        </>
    );
}