import { CustomClaimValue, Device, HardwareStatuses, useAuth } from "@app/shared";
import { useMemo, useState } from "react";
import { useFetch } from "../../hooks";
import { CpuTemperatureChart, CpuUtilizationChart, PowerUsageChart, RamUsageChart } from "./charts";
import { Icons } from "./Icons";
import { Select } from "./inputs";
import { PageContainer } from "./PageContainer";

type DeviceStatusChartsProps = {
    device?: Device;
};

const getPeriodByHours = (hours: number) => {
    if (hours <= 12) return 5;
    else if (hours <= 24) return 15;
    else if (hours <= 72) return 30;
    else if (hours <= 168) return 60;
    else if (hours <= 744) return 300;
    return 600;
}

export const DeviceStatusCharts: React.FC<DeviceStatusChartsProps> = ({ device }) => {
    const [hoursToDisplay, setHoursToDisplay] = useState(1);
    const user = useAuth();
    const isSuperAdmin =
        user.userClaims?.some(uc => uc.claims.includes(CustomClaimValue.SuperAdmin));

    const [statusHistory, historyError, historyHelpers] = useFetch(
        () => (device ? HardwareStatuses.getHistoryForDevice(device.id, hoursToDisplay, getPeriodByHours(hoursToDisplay)) : Promise.resolve(undefined)),
        [device, hoursToDisplay]
    );

    const selectOptions = [
        { value: "1", label: "1 Hour" },
        { value: "4", label: "4 Hours" },
        { value: "8", label: "8 Hours" },
        { value: "12", label: "12 Hours" },
        { value: "24", label: "1 Day" },
        { value: "72", label: "3 Days" },
        { value: "168", label: "1 Week" },
        { value: "730", label: "1 Month" }
    ];

    const Content = useMemo(() => {


        if (historyError && !historyHelpers.isLoading) {
            return (
                <h2 className="text-center w-screen">
                    {isSuperAdmin ? historyError : "An error occurred"}
                </h2>
            );
        }

        if (!statusHistory?.length && !historyHelpers.isLoading) {
            return <h2 className="text-center w-screen">No Data</h2>;
        }

        return (
            <>
                <CpuUtilizationChart data={statusHistory?.map(x => x.cpuUsage!)} title="CPU Utilization" />
                <CpuTemperatureChart data={statusHistory?.map(x => x.cpuTemps!)} title="Temperature" />
                <RamUsageChart data={statusHistory?.map(x => x.ramUsage!)} title="RAM Usage" />
                <PowerUsageChart data={statusHistory?.map(x => x.powerUsage!)} title="Power Usage" />
            </>
        );
    }, [statusHistory, historyError, historyHelpers.isLoading, isSuperAdmin]);

    return (
        <PageContainer
            Icon={Icons.StatsDots}
            title="Hardware Status"
            titleRight={
                <Select
                    options={selectOptions}
                    value={hoursToDisplay.toString()}
                    onChange={(e) => setHoursToDisplay(parseInt(e.target.value))}
                />
            }
        >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 h-[700px]">{Content}</div>
        </PageContainer>
    );
}