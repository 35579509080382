import { ClientEndpoint, IClientEndpoint } from "./ClientEndpoints.ClientEndpoint.gen";
import { Facility, IFacility } from "./Facility.gen";
import { Device, IDevice } from "./Device.gen";
import { DetectionZone, IDetectionZone } from "./DetectionZone.gen";
import { ScreenLine, IScreenLine } from "./ScreenLine.gen";
import { EventTriggerDevice, IEventTriggerDevice } from "./EventTriggers.EventTriggerDevice.gen";
import { EventTriggerFacility, IEventTriggerFacility } from "./EventTriggers.EventTriggerFacility.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { Single, ISingle } from "./System.Single.gen";
import { TargetTypes } from "./Shared.TargetTypes.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, IsOptional } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IDetectionZoneEntryEventTrigger {
	clientEndpoint: IClientEndpoint | undefined;

	facilities: IFacility[] | undefined;

	devices: IDevice[] | undefined;

	detectionZone: IDetectionZone | undefined;

	screenLine: IScreenLine | undefined;

	eventTriggerDevices: IEventTriggerDevice[] | undefined;

	eventTriggerFacilities: IEventTriggerFacility[] | undefined;

	description: string | undefined;

	id: string | undefined;

	organizationId: string | undefined;

	detectionZoneId: string | undefined;

	screenLineId: string | undefined;

	clientEndpointId: string | undefined;

	targetFilter: TargetTypes | number ;

	processOnDevice: boolean;

	minValue: number | undefined;

	maxValue: number | undefined;

	additionalValue: number | undefined;

	endpointConfig: string | undefined;

	name: string | undefined;

	$type?: string | undefined;
}
export class DetectionZoneEntryEventTrigger {
	constructor(data?: IDetectionZoneEntryEventTrigger) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.clientEndpoint) { this.clientEndpoint = new ClientEndpoint(data.clientEndpoint); }
		this.facilities = data.facilities ? data.facilities.map((f: any) => new Facility(f)) : [];
		this.devices = data.devices ? data.devices.map((d: any) => new Device(d)) : [];
		if (data.detectionZone) { this.detectionZone = new DetectionZone(data.detectionZone); }
		if (data.screenLine) { this.screenLine = new ScreenLine(data.screenLine); }
		this.eventTriggerDevices = data.eventTriggerDevices ? data.eventTriggerDevices.map((e: any) => new EventTriggerDevice(e)) : [];
		this.eventTriggerFacilities = data.eventTriggerFacilities ? data.eventTriggerFacilities.map((e: any) => new EventTriggerFacility(e)) : [];
	}

	clientEndpoint: ClientEndpoint | undefined;

	facilities: Facility[] | undefined = [];

	devices: Device[] | undefined = [];

	detectionZone: DetectionZone | undefined;

	screenLine: ScreenLine | undefined;

	eventTriggerDevices: EventTriggerDevice[] | undefined = [];

	eventTriggerFacilities: EventTriggerFacility[] | undefined = [];

	description: string | undefined;

	id: string | undefined;

	organizationId: string | undefined;

	detectionZoneId: string | undefined;

	screenLineId: string | undefined;

	@IsNotEmpty()
	clientEndpointId: string | undefined;

	targetFilter: TargetTypes | number  = 0;

	processOnDevice: boolean = false;

	minValue: number | undefined;

	maxValue: number | undefined;

	additionalValue: number | undefined;

	endpointConfig: string | undefined;

	name: string | undefined;

	static $type: string = "CoreApi.Models.EventTriggers.DetectionZoneEntryEventTrigger, ";
	$type?: string | undefined = DetectionZoneEntryEventTrigger.$type;
}

