import { EventTrigger, EventTriggerFacility, EventTriggers, MaxServerIntValue } from "@app/shared";
import { DropDownMenu, IconLink, Icons, PageContainer, SortableTable } from ".";
import { useConfirmDialog, useEditModelDialog, useFetch } from "../../hooks";
import { EventTriggerEditor } from "./EditEventTrigger/EventTriggerEditor";

type EventTriggerTableProps = {
    organizationId: string;
    facilityId?: string;
};

export const EventTriggerTable: React.FC<EventTriggerTableProps> = (props) => {
    const [eventTriggers, , fetchHelpers] = useFetch(() => EventTriggers.getByFacilityId(props.organizationId, props.facilityId));
    const triggerDialog = useEditModelDialog(EventTrigger);
    const confirmDialog = useConfirmDialog();

    const handleAddTriggerOnClick = () => {
        triggerDialog.show(async (toSave: Partial<EventTrigger>) => {
            await EventTriggers.update(toSave as EventTrigger);
            await fetchHelpers.refreshData();
        }, "Add Event Trigger", { $type: "", organizationId: props.organizationId, facilityId: props.facilityId, eventTriggerFacilities: props.facilityId ? [{ facilityId: props.facilityId } as EventTriggerFacility] : [], targetFilter: MaxServerIntValue } as Partial<EventTrigger>);
    };

    const handleEditTriggerOnClick = (toEdit: EventTrigger | undefined) => {
        if (!toEdit) return;

        triggerDialog.show(async (toSave: Partial<EventTrigger>) => {
            await EventTriggers.update(toSave as EventTrigger);
            await fetchHelpers.refreshData();
        }, "Edit Event Trigger", toEdit);
    };

    const handleDeleteTriggerOnClick = (toDelete: EventTrigger | undefined) => {
        if (!toDelete) return;
        confirmDialog.show("Delete Event?", "Are you sure you want to delete this event trigger?", "Delete",
            async () => {
                await EventTriggers.deleteTrigger(toDelete.id);
                await fetchHelpers.refreshData();
            });
    };

    return (
        <>
            <PageContainer Icon={Icons.Warning} title="Event Triggers" titleRight={<IconLink Icon={Icons.PlusCircle} onClick={handleAddTriggerOnClick}>Add New</IconLink>}>
                <SortableTable
                    isLoading={fetchHelpers.isLoading}
                    emptyValue={<p className="m-5">No Event Triggers Found</p>}
                    columns={[
                        {
                            header: "Trigger",
                            width: 150,
                            dataKey: "name"
                        },
                        {
                            header: "Facilities",
                            width: 150,
                            renderFunc: (e) => !!!e?.facilities?.length ? "Any" : e.facilities.map(f => f.name).join(", ")
                        },
                        {
                            header: "SL / DZ Filter",
                            width: 150,
                            renderFunc: (e) => (e?.screenLineId || e?.detectionZoneId) ? (e?.screenLine?.name ?? "") + " " + (e?.detectionZone?.name ?? "") : "Any"
                        },
                        {
                            header: "Devices",
                            width: 125,
                            renderFunc: (e) => !!!e?.devices?.length ? "Any" : e.devices.map(f => f.name).join(", ")
                        },
                        {
                            header: "Endpoint",
                            width: 75,
                            renderFunc: (e) => e?.clientEndpoint?.endpointName
                        },
                        // {
                        //     header: "Description",
                        //     width: 250,
                        //     dataKey: "description"
                        // },
                        {
                            header: "",
                            width: 25,
                            renderFunc: (e) => (
                                <DropDownMenu>
                                    <IconLink onClick={() => handleEditTriggerOnClick(e)} Icon={Icons.Edit}>Edit</IconLink>
                                    <IconLink onClick={() => handleDeleteTriggerOnClick(e)} Icon={Icons.Trash}>Delete</IconLink>
                                </DropDownMenu>
                            )
                        }
                    ]}
                    data={eventTriggers} />
            </PageContainer>
            {confirmDialog.renderDialog()}
            {triggerDialog.renderDialog((model, helpers) => (
                model &&
                <EventTriggerEditor trigger={model as EventTrigger} onChange={(newModel) => newModel && helpers.replaceModel(newModel)} facilityId={props.facilityId} />
            ))}
        </>
    );
}
