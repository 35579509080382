import { TrafficReportProps } from "./IConfiguredTrafficReport";
import { AggregatedTargetAttribute, AggregationPeriod, LocationUsageGrouping, Reports, useAuth } from "@app/shared";
import { useContext, useEffect, useMemo } from "react";
import { colorMap, getGroupingDisplayName, ReportContext } from "./reportHelpers";
import { useFetch } from "../../hooks";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { Bar, ChartProps } from 'react-chartjs-2';
import { Chart, registerables, TooltipItem } from 'chart.js';

Chart.register(...registerables);

export function GetFacilityUsageDataLoader(aggLevel: AggregationPeriod, durationFormatter: (timezone: string, periodStart: Date) => string): React.FC<TrafficReportProps> {
    return function MYFC(props: TrafficReportProps) {
        const { searchParams, reportConfig, setIsLoading } = useContext(ReportContext);
        const { numberFormatter } = useAuth();
        const { grouping, chartTimezone } = reportConfig;
        const { size } = props;
        const [data, error, { isLoading }] = useFetch(() => Reports.facilityScreenLineTotals(aggLevel, searchParams), [aggLevel, searchParams]);
        useEffect(() => setIsLoading(isLoading), [isLoading, setIsLoading]);
        const sortedData = useMemo(() => {
            if (!data?.length) { return [] }
            return [...data].sort((a, b) => a.periodStart!.getTime() - b.periodStart!.getTime())
        }, [data]);

        const xValues = useMemo(() => Array.from(new Set(sortedData.map(item => item.periodStart!.getTime()) ?? [])).map(x => new Date(x)), [sortedData]);

        let datasets = []
        if (grouping === AggregatedTargetAttribute.None) {
            datasets = [{
                label: 'Inbound',
                data: sortedData.map(x => x.inCount),
                backgroundColor: colorMap[grouping][0],
                stack: 'Inbound'
            }, {
                label: 'Outbound',
                data: sortedData.map(x => x.outCount),
                backgroundColor: colorMap[grouping][0],
                stack: 'Outbound'
            }];
        } else {
            const stacked = sortedData.reduce<Map<number, LocationUsageGrouping[]>>((acc, item) => {
                if (!acc.has(item.aggregatedValue!)) {
                    acc.set(item.aggregatedValue!, []);
                }
                acc.get(item.aggregatedValue!)!.push(item);
                return acc;
            }, new Map()).values();
            datasets = stacked.flatMap((s, idx) => ([{
                label: getGroupingDisplayName(grouping, s[0].aggregatedValue!),
                data: s.map(x => x.inCount),
                backgroundColor: colorMap[grouping][s[0].aggregatedValue!],
                stack: 'Inbound'
            }, {
                label: getGroupingDisplayName(grouping, s[0].aggregatedValue!),
                data: s.map(x => x.outCount),
                backgroundColor: colorMap[grouping][s[0].aggregatedValue!],
                stack: 'Outbound'
            }])).toArray();
        }

        const chartjsProps = {
            data: {
                labels: xValues.map(x => durationFormatter(chartTimezone, x)),
                datasets
            },
            options: {
                // onClick: (evt, elements) => {
                //     const year = data[elements[0].index].year;
                //     const rParams = { ...reportConfig };
                //     rParams.selectedReportId = AverageDailyVolumeByMonth.key;
                //     rParams.inclusiveStart = getZonedDateAsUTC(year, 1, 1, reportConfig.chartTimezone);
                //     rParams.exclusiveEnd = getZonedDateAsUTC((year) + 1, 1, 1, reportConfig.chartTimezone);
                //     reportConfig.context.setReportConfig(rParams);
                // },
                plugins: {
                    legend: {
                        display: grouping !== AggregatedTargetAttribute.None,
                        labels: {
                            filter: (legendItem, chartData) => {
                                const datasetIndex = legendItem.datasetIndex;
                                if (datasetIndex === undefined || !chartData?.datasets) return false;

                                // Check if the dataset has any non-zero values
                                return chartData.datasets[datasetIndex].data.some(value => value !== 0);
                            },
                            generateLabels: (chart) => {
                                const datasets = chart.data.datasets;
                                const uniqueLabels: Array<{ text: string | undefined, fillStyle: any }> = [];

                                // Create unique entries for each color
                                datasets.forEach((dataset) => {
                                    const existing = uniqueLabels.find(
                                        (entry) => entry.text === dataset.label && entry.fillStyle === dataset.backgroundColor
                                    );
                                    if (!existing) {
                                        uniqueLabels.push({
                                            text: dataset.label,
                                            fillStyle: dataset.backgroundColor,
                                        });
                                    }
                                });

                                return uniqueLabels;
                            },
                        }
                    },
                    tooltip: {
                        filter: (tooltipItem: TooltipItem<'bar'>) => {
                            return !Number.isNaN(tooltipItem.raw) && tooltipItem.raw !== 0; // Exclude zero values
                        },
                        callbacks: {
                            footer: (tooltipItems: TooltipItem<'bar'>[]) => {
                                const inTotal = tooltipItems.reduce((sum, tooltipItem) => {
                                    return sum + (tooltipItem.dataset.stack === "Inbound" ? tooltipItem.raw as number : 0);
                                }, 0);
                                const outTotal = tooltipItems.reduce((sum, tooltipItem) => {
                                    return sum + (tooltipItem.dataset.stack === "Outbound" ? tooltipItem.raw as number : 0);
                                }, 0);
                                return [`Inbound:  ${numberFormatter.format(inTotal)}`, `Outbound: ${numberFormatter.format(outTotal)}`];
                            },
                            label: (context) => {
                                const dataset = context.dataset;

                                return dataset.stack === 'Inbound' ? `${dataset.label} In ${context.raw}`
                                    : [`${dataset.label} Out: ${context.raw}`, ''];
                            },
                        },
                    },
                },
                interaction: {
                    mode: 'index', // Ensures all datasets for the same label are shown
                    intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        stacked: true
                    },
                    x: {
                        stacked: true
                    }
                }
            }
        } as Omit<ChartProps<"bar", (number | [number, number] | null)[], unknown>, "type">;

        return <ReportLoadingErrorWrapper error={error} hasNoData={!!!xValues?.length} isLoading={isLoading} size={props.size}>
            <Bar  {...chartjsProps as ChartProps<'bar'>} width={props.size.width} height={size.height ?? 1100} ></Bar>
        </ReportLoadingErrorWrapper>

    };
}