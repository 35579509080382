export enum WebRelayModel {
	Single = 0,
	Quad_Original = 1,
	Single_400_Series = 2,
	Quad_400_Series = 3,
}

export const WebRelayModelDisplayNames: { [key in keyof typeof WebRelayModel]?: string} = {
	"Single": "Single",
	0 : "Single",
	"Quad_Original": "Quad_Original",
	1 : "Quad_Original",
	"Single_400_Series": "Single_400_Series",
	2 : "Single_400_Series",
	"Quad_400_Series": "Quad_400_Series",
	3 : "Quad_400_Series",
}

export const getWebRelayModelDisplayName = (key: WebRelayModel | (keyof typeof WebRelayModel & string)) => {
	return WebRelayModelDisplayNames[key];
}

