import { Facility, ScreenLine, Vector2 } from "@app/shared";
import { calculateAngles, useFacilityCoordsHelper } from "../../../../helpers";
import { Input, InputRow, NumericInput } from "../../inputs";

type ScreenLineConfigProps = {
    screenLine: ScreenLine;
    facility: Facility;
    onChange: (screenLine: ScreenLine) => void;
    isEditable?: boolean;
    isCollapsed: boolean;
    cantDeleteReason: string | undefined;
};

export const ScreenLineConfig: React.FC<ScreenLineConfigProps> = ({ facility, screenLine, onChange, isCollapsed, isEditable = false, cantDeleteReason }) => {
    const coordsHelper = useFacilityCoordsHelper(facility);
    const handleOnChange = (key: keyof ScreenLine, value: ScreenLine[keyof ScreenLine]) => {
        onChange(new ScreenLine({ ...screenLine, [key]: value }));
    };

    const handleOnChangePoint = (ind: "start" | "end", key: keyof google.maps.LatLngLiteral, value: undefined | Vector2[keyof Vector2]) => {
        if ("start" === ind) {
            const start = coordsHelper.fromLatLng(new google.maps.LatLng({ lat: startLatLng?.lat() ?? 0, lng: startLatLng?.lng() ?? 0, [key]: value })); //startLatLngscreenLine.points![0];
            if (start.x !== 0 && start.y !== 0) {
                const startVector = new Vector2({ ...start, [key]: value });
                const angles = calculateAngles(startVector, screenLine.points![1], coordsHelper, screenLine.reverseInOut);
                onChange(new ScreenLine({ ...screenLine, points: [startVector, screenLine.points![1]], outboundDirection: angles.outbound, inboundDirection: angles.inbound }));
            }
        } else {
            const end = coordsHelper.fromLatLng(new google.maps.LatLng({ lat: endLatLng?.lat() ?? 0, lng: endLatLng?.lng() ?? 0, [key]: value })); //startLatLngscreenLine.points![0];
            if (end.x !== 0 && end.y !== 0) {
                const endVector = new Vector2({ ...end, [key]: value });
                const angles = calculateAngles(screenLine.points![0], endVector, coordsHelper, screenLine.reverseInOut);
                onChange(new ScreenLine({ ...screenLine, points: [screenLine.points![0], endVector], outboundDirection: angles.outbound, inboundDirection: angles.inbound }));
            }
        }
    };

    const invertInboundOutbound = () => {
        screenLine.setReverseInOut(!screenLine.reverseInOut);
        const angles = calculateAngles(screenLine.points![0], screenLine.points![1], coordsHelper, screenLine.reverseInOut);
        onChange(new ScreenLine({ ...screenLine, outboundDirection: angles.outbound, inboundDirection: angles.inbound }));
    };

    const startLatLng = screenLine.points?.[0] ? coordsHelper.toLatLng(screenLine.points?.[0]) : null;
    const endLatLng = screenLine.points?.[1] ? coordsHelper.toLatLng(screenLine.points?.[1]) : null;

    return isCollapsed ? null : (
        <>
            <InputRow>
                <Input label="Name" onChange={(e) => handleOnChange("name", e.target.value)} value={screenLine.name} disabled={!isEditable} />
            </InputRow>
            <InputRow>
                <div className="grid grid-cols-2 gap-5">
                    <NumericInput label="Start Longitude" onChange={(e) => handleOnChangePoint("start", "lng", e)} value={startLatLng?.lng()} disabled={!isEditable} debounce={0} />
                    <NumericInput label="Start Latitude" onChange={(e) => handleOnChangePoint("start", "lat", e)} value={startLatLng?.lat()} disabled={!isEditable} debounce={0} />
                    <NumericInput label="End Longitude" onChange={(e) => handleOnChangePoint("end", "lng", e)} value={endLatLng?.lng()} disabled={!isEditable} debounce={0} />
                    <NumericInput label="End Latitude" onChange={(e) => handleOnChangePoint("end", "lat", e)} value={endLatLng?.lat()} disabled={!isEditable} debounce={0} />
                    <div>
                        <p className="text-sm text-muted-body-text">Inbound Direction</p>
                        <p>{screenLine.inboundDirection}</p>
                    </div>
                    <div>
                        <p className="text-sm text-muted-body-text">Outbound Direction</p>
                        <p>{screenLine.outboundDirection} </p>
                    </div>
                </div>
                <div className="flex justify-center items-center mt-4">
                    {isEditable ? (
                        <button onClick={invertInboundOutbound} className="bg-brand-primary text-bright-text font-bold py-2 px-4 rounded shadow text-sm">
                            Reverse In / Out
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            </InputRow>
            <p className="text-center font-medium text-sm mt-4" style={{ color: "red" }}>
                {cantDeleteReason}
            </p>
        </>
    );
};
