export enum WebRelayState {
	Off = 0,
	On = 1,
	PulseOrReboot = 2,
	DisableReboot = 3,
	EnableReboot = 4,
}

export const WebRelayStateDisplayNames: { [key in keyof typeof WebRelayState]?: string} = {
	"Off": "Off",
	0 : "Off",
	"On": "On",
	1 : "On",
	"PulseOrReboot": "Pulse or Reboot",
	2 : "Pulse or Reboot",
	"DisableReboot": "Disable Reboot",
	3 : "Disable Reboot",
	"EnableReboot": "Enable Reboot",
	4 : "Enable Reboot",
}

export const getWebRelayStateDisplayName = (key: WebRelayState | (keyof typeof WebRelayState & string)) => {
	return WebRelayStateDisplayNames[key];
}

