import { AggregationPeriod, Reports, TotalVolumeByPeriod } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { useFetch } from "../../hooks";
import { useContext, useEffect } from "react";
import { startOfWeek, endOfWeek } from 'date-fns';
import { ReportContext } from "./reportHelpers";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { Bar, ChartProps, Line } from "react-chartjs-2";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { extLightThemeColors } from "../../styles/chartThemeColors";

const WeeklyComparison: React.FC<TrafficReportProps & { data: TotalVolumeByPeriod[] }> = props => {
    const { reportConfig } = useContext(ReportContext);
    const { chartType, chartTimezone } = reportConfig;
    const { title, subTitle, data } = props;
    interface WeeklyDataset {
        label: string;
        data: number[]; // 7 days * 24 hours
    }
    const z = format(data[0].periodStart!, 'yyyy', { timeZone: chartTimezone });
    const isSameYear = data.every(x => format(x.periodStart!, 'yyyy', { timeZone: chartTimezone }) === z);
    function groupHourlyDataByWeek(
        data: TotalVolumeByPeriod[],
        timezone: string
    ): WeeklyDataset[] {
        const grouped: Record<string, number[]> = {};

        data.forEach((entry) => {
            const utcDate = timezone !== 'UTC' ? utcToZonedTime(entry.periodStart!, timezone) : entry.periodStart!;
            const weekStart = zonedTimeToUtc(startOfWeek(utcDate, { weekStartsOn: 0 }), chartTimezone); // Sunday start            
            const weekEnd = zonedTimeToUtc(endOfWeek(utcDate, { weekStartsOn: 0 }), chartTimezone);

            const weekKey = `${format(weekStart, isSameYear ? 'MMM d' : "yyyy-MM-dd")} to ${format(
                weekEnd,
                isSameYear ? 'MMM d' : "yyyy-MM-dd"
            )}`;
            const hour = utcDate.getHours();
            const day = utcDate.getDay();

            // Initialize array for the week
            if (!grouped[weekKey]) {
                grouped[weekKey] = Array(7 * 24).fill(0); // 7 days * 24 hours
            }

            // Update the hourly volume
            const index = day * 24 + hour;
            grouped[weekKey][index] += entry.count; // Sum volumes if overlapping
        });

        // Convert grouped data into datasets
        return Object.entries(grouped).map(([label, data]) => ({
            label,
            data,
        }));
    }
    const datasets = groupHourlyDataByWeek(data, chartTimezone).map((wd, idx) => ({
        label: wd.label,
        data: wd.data,
        backgroundColor: extLightThemeColors[idx],
        borderColor: extLightThemeColors[idx]
    }));

    const chartjsProps = {
        data: {
            datasets,
            labels: Array.from({ length: 7 * 24 }, (_, i) => {
                const day = Math.floor(i / 24);
                const hour = i % 24;
                return format(new Date(2020, 0, 5 + day, hour), 'EEEE H:00');
            })
        },
        options: {
            plugins: {
                title: {
                    display: !!title,
                    text: !!!title ? undefined : typeof title !== 'string' ? title(data) : title,
                    font: {
                        size: 24,
                        weight: 'bold'
                    },
                },
                subtitle: {
                    display: !!subTitle,
                    text: !!!subTitle ? undefined : typeof subTitle !== 'string' ? subTitle(data) : subTitle,
                    font: {
                        size: 16,
                        weight: 'bold'
                    },
                },
                legend: {
                    display: true
                }
            },
            interaction: {
                mode: 'index', // Ensures all datasets for the same label are shown
                intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
            },
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: false
                },
                x: {
                    position: 'bottom', // Time at the bottom
                    ticks: {
                        callback: (value: number, i) => {
                            const day = Math.floor(i / 24);
                            const hour = i % 24;
                            return format(new Date(2020, 0, 5 + day, hour), 'H:00');
                        },
                        maxTicksLimit: 7 * 6
                    },
                },
                x2: {
                    position: 'top',
                    type: 'linear', // Use linear scale for precise alignment
                    ticks: {
                        callback: (value: number, index, ticks) => {

                            // Show labels only at ~midpoints
                            if (index % 3 === 1) {
                                const dayIndex = Math.floor(index / 3); // Determine day index
                                return format(new Date(2020, 0, 5 + dayIndex), 'EEEE');
                            }
                            return ''; // Empty for non-midpoints
                        },
                        maxTicksLimit: 7 * 6,
                        align: 'center',
                    },
                    grid: {
                        drawOnChartArea: false, // Prevent gridlines for this axis
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                    },
                },
            }
        }
    } as Omit<ChartProps<"line" | "bar", (number | [number, number] | null)[], unknown>, "type">;

    return chartType === 'line' ?
        <Line  {...chartjsProps as ChartProps<'line'>} width={props.size.width} height={props.size.height} ></Line> :
        <Bar  {...chartjsProps as ChartProps<'bar'>} width={props.size.width} height={props.size.height} ></Bar>;
}

export const WeeklyComparisonDataLoader: React.FC<TrafficReportProps> = props => {
    const { reportConfig, searchParams, setIsLoading } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(reportConfig.grouping, AggregationPeriod.Hour, searchParams), [searchParams]);
    useEffect(() => setIsLoading(isLoading), [isLoading, setIsLoading]);
    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        <WeeklyComparison {...props} data={data!} />
    </ReportLoadingErrorWrapper>);
}

export const WeeklyVolumeComparisonReport = {
    name: "Weekly Volume Comparison Report",
    description: "This report shows the hourly volume for every week in the selected data range",
    component: WeeklyComparisonDataLoader,
    key: 'wvc',
    defaultChartType: 'line',
    defaultRange: DateOptions.ThisMonth,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: false
    }
} as IConfiguredTrafficReport;