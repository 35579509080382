import { Int64, IInt64 } from "./System.Int64.gen";

export interface IMemoryCacheStatistics {
	currentEntryCount: number;

	currentEstimatedSize: number | undefined;

	totalMisses: number;

	totalHits: number;

	$type?: string | undefined;
}
export class MemoryCacheStatistics {
	constructor(data?: IMemoryCacheStatistics) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	currentEntryCount: number = 0;

	currentEstimatedSize: number | undefined;

	totalMisses: number = 0;

	totalHits: number = 0;

	static $type: string = "Microsoft.Extensions.Caching.Memory.MemoryCacheStatistics, ";
	$type?: string | undefined = MemoryCacheStatistics.$type;
}

