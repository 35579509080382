/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get } from "./api_client";
import { CpuUsageInfo, ICpuUsageInfo, RamUsageInfo, IRamUsageInfo, PowerUsageInfo, IPowerUsageInfo, CpuTempInfo, ICpuTempInfo, HardwareStatusSlim, IHardwareStatusSlim, HardwareStatusHistory, IHardwareStatusHistory } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getCpuUsageHistoryForDevice(deviceId?: string, numberOfHours?: number, periodInMinutes?: number): ApiResult<CpuUsageInfo[]> {
		const queryParams = [numberOfHours !== undefined ? `numberOfHours=${numberOfHours}` : undefined,
			periodInMinutes !== undefined ? `periodInMinutes=${periodInMinutes}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<ICpuUsageInfo[]>(`/api/hardwarestatus/cpu-usage-for-device/${ deviceId }${query}`).then(result => result === undefined ? undefined : result.map(o => new CpuUsageInfo(o)));
}

export async function getRamUsageInfoForDevice(deviceId?: string, numberOfHours?: number, periodInMinutes?: number): ApiResult<RamUsageInfo[]> {
		const queryParams = [numberOfHours !== undefined ? `numberOfHours=${numberOfHours}` : undefined,
			periodInMinutes !== undefined ? `periodInMinutes=${periodInMinutes}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IRamUsageInfo[]>(`/api/hardwarestatus/ram-usage-for-device/${ deviceId }${query}`).then(result => result === undefined ? undefined : result.map(o => new RamUsageInfo(o)));
}

export async function getPowerUsageInfoForDevice(deviceId?: string, numberOfHours?: number, periodInMinutes?: number): ApiResult<PowerUsageInfo[]> {
		const queryParams = [numberOfHours !== undefined ? `numberOfHours=${numberOfHours}` : undefined,
			periodInMinutes !== undefined ? `periodInMinutes=${periodInMinutes}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IPowerUsageInfo[]>(`/api/hardwarestatus/power-usage-for-device/${ deviceId }${query}`).then(result => result === undefined ? undefined : result.map(o => new PowerUsageInfo(o)));
}

export async function getCpuTempHistoryForDevice(deviceId?: string, numberOfHours?: number, periodInMinutes?: number): ApiResult<CpuTempInfo[]> {
		const queryParams = [numberOfHours !== undefined ? `numberOfHours=${numberOfHours}` : undefined,
			periodInMinutes !== undefined ? `periodInMinutes=${periodInMinutes}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<ICpuTempInfo[]>(`/api/hardwarestatus/cpu-temp-for-device/${ deviceId }${query}`).then(result => result === undefined ? undefined : result.map(o => new CpuTempInfo(o)));
}

export async function getRecentForDevice(deviceId?: string, limit?: number): ApiResult<HardwareStatusSlim[]> {
		const queryParams = [limit !== undefined ? `limit=${limit}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IHardwareStatusSlim[]>(`/api/hardwarestatus/recent-for-device/${ deviceId }${query}`).then(result => result === undefined ? undefined : result.map(o => new HardwareStatusSlim(o)));
}

export async function getHistoryForDevice(deviceId?: string, numberOfHours?: number, periodInMinutes?: number): ApiResult<HardwareStatusHistory[]> {
		const queryParams = [numberOfHours !== undefined ? `numberOfHours=${numberOfHours}` : undefined,
			periodInMinutes !== undefined ? `periodInMinutes=${periodInMinutes}` : undefined]
		var query = queryParams.filter((v) => (v !== undefined)).join('&');
		if (query && query.length)
			query = '?' + query;

	return get<IHardwareStatusHistory[]>(`/api/hardwarestatus/history-for-device/${ deviceId }${query}`).then(result => result === undefined ? undefined : result.map(o => new HardwareStatusHistory(o)));
}
