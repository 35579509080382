import { Guid, IGuid } from "./System.Guid.gen";
import { ErrorDetailsEntity, IErrorDetailsEntity } from "./ErrorDetailsEntity.gen";

export interface IErrorSummary {
	hashId: string | undefined;

	lastOccurrence: string | undefined;

	count: number;

	deviceIds: string[] | undefined;

	details: IErrorDetailsEntity | undefined;

	$type?: string | undefined;
}
export class ErrorSummary {
	constructor(data?: IErrorSummary) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.lastOccurrence) { this.lastOccurrence = new Date(data.lastOccurrence); }
		if (data.details) { this.details = new ErrorDetailsEntity(data.details); }
	}

	hashId: string | undefined;

	lastOccurrence: Date | undefined = new Date();

	count: number = 0;

	deviceIds: string[] | undefined;

	details: ErrorDetailsEntity | undefined;

	static $type: string = "CoreApi.Data.ErrorSummary, ";
	$type?: string | undefined = ErrorSummary.$type;
}

