import { AuthContextProvider } from "@app/shared";
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import { ContainerContextProvider } from "./components";
import { Pages } from './components/pages';
import { PageNotFound } from "./components/pages/PageNotFound";
import './styles/index.css';
import './styles/tailwind.css';
import { AdminNagScreen } from "./components/shared/AdminNagScreen";

const PageRoutes = () => useRoutes([
  ...Object.values(Pages).map((page) => ({
    path: page.routeUrl,
    element: <page.page />
  })
  ),
  //Add any other routes here
  { path: "/", element: <Navigate to={Pages.ViewFacilityGroups.getUrl()} /> },
  { path: "*", element: <PageNotFound /> }
]);


export default function App() {
  return (
    <AuthContextProvider>
      <AdminNagScreen>
        <ContainerContextProvider>
          <BrowserRouter>
            <PageRoutes />
          </BrowserRouter>
        </ContainerContextProvider>
      </AdminNagScreen>
    </AuthContextProvider>
  )
}