import { LaneLine, Vector2 } from "@app/shared";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ICoordsHelper, MapContext, MapObjArray, animateCircle, createGooglePolyline, createVertexCircle, debounce, useFacilityCoordsHelper } from "../../../../helpers";
import { usePolygonScaleAndRotation } from "../../../../hooks";
import { MapShapeProps } from "../MapShape";

export interface LaneLineMapShapeProps extends MapShapeProps<LaneLine> { color?: string };
export const LaneLineMapShape: React.FC<LaneLineMapShapeProps> = ({ facility, shape, isEditable, isSelectable, onUpdate, onClick, isSelected, color }) => {
    // Turn Type - Straight, left, Right, UTurn - null
    // Lane Number - needs to be positive, above 0, or null
    const [parentMap, setBounds] = useContext(MapContext);
    const listeners = useRef([] as Array<google.maps.MapsEventListener>);
    const [polyLaneLine, setpolyLaneLine] = useState<google.maps.Polyline>();
    const mapObjects = useRef([] as MapObjArray);
    const vertexCircles = useRef([] as Array<google.maps.Circle>);
    const didMountRef = useRef(false);
    const fCoordsHelper = useFacilityCoordsHelper(facility);

    const DEFAULT_COLOR = "green"

    useEffect(() => {//create/update google poly for shape        
        if (parentMap && shape?.points?.length) {
            const [poly] = initPoly(shape, parentMap, fCoordsHelper, color ?? DEFAULT_COLOR);
            setpolyLaneLine(poly);
            setBounds(curr => {
                poly?.getPath().getArray().forEach(pt => curr.extend(pt));
                return curr;
            })
            return () => {
                poly.setMap(null); poly.setVisible(false);
                mapObjects.current?.forEach(mo => { mo?.setVisible(false); mo?.setMap(null); });
                setpolyLaneLine(undefined);
                mapObjects.current = [];
            }
        }
    }, [parentMap, shape, color, fCoordsHelper, setBounds]);

    const handleClick = (e: google.maps.MapMouseEvent) => {
        if (!e.latLng || !shape) { return; }
        const dz = shape;
        const v2 = fCoordsHelper.fromLatLng(e.latLng);
        if (!dz.points?.length) {
            dz.points = [v2];
        } else {
            dz.points.push(v2);
        }
        onUpdate?.(Object.assign(new LaneLine(), { ...shape, points: dz.points }));
    }

    const memoHandleClick = useCallback(handleClick, [shape, onUpdate]);//eslint-disable-line react-hooks/exhaustive-deps

    const handleMove = (e: google.maps.MapMouseEvent) => {

        if (!polyLaneLine || !isEditable || !e.latLng) return;

        const path = polyLaneLine.getPath().getArray();
        if ((shape?.points?.length !== undefined) && shape.points.length < path.length) {
            path.pop();
        }

        polyLaneLine.setPath([...path, e.latLng]);
    };
    const memoHandleMove = useCallback(handleMove, [polyLaneLine, isEditable, shape]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { //editable    
        if (isEditable && parentMap) {
            if (!shape?.isComplete()) {
                listeners.current.push(parentMap.addListener('click', memoHandleClick));
                listeners.current.push(parentMap.addListener('mouseout', () => polyLaneLine?.setPath(shape?.points?.map(fCoordsHelper.toLatLng) ?? [])));
                listeners.current.push(parentMap.addListener('mousemove', memoHandleMove));
            }
            else if (polyLaneLine) {
                vertexCircles.current = polyLaneLine.getPath().getArray().map((pos, idx) => {
                    const isClickable = shape?.isComplete() || idx !== 0;
                    const posMark = createVertexCircle(pos, parentMap, isClickable, color ?? DEFAULT_COLOR);
                    posMark.addListener('dragend', (d: google.maps.MapMouseEvent) => {
                        if (d.latLng && polyLaneLine) {
                            const path = polyLaneLine.getPath();
                            path.setAt(idx, d.latLng);
                            vertexCircles.current[idx]?.setCenter(d.latLng);
                            const points = path.getArray().map(fCoordsHelper.fromLatLng);
                            onUpdate?.(Object.assign(new LaneLine(), { ...shape, points: points }));
                        }
                    });
                    posMark.addListener('drag', (d: google.maps.MapMouseEvent) => {
                        if (d.latLng && polyLaneLine) {
                            const path = polyLaneLine.getPath();
                            path.setAt(idx, d.latLng);
                            polyLaneLine?.setPath(path)
                        }
                    });

                    return posMark;
                });
                vertexCircles.current.forEach(vc => mapObjects.current.push(vc));
                if (shape?.isComplete()) {
                    polyLaneLine.setDraggable(true);
                    listeners.current.push(polyLaneLine.addListener('dragstart', handlePolyDragStart));
                    listeners.current.push(polyLaneLine.addListener('dragend', handlePolyDragEnd));
                }
            }
        }

        return () => {
            listeners.current.forEach(l => l?.remove());
            listeners.current = [];
            vertexCircles.current.forEach(l => { l.setVisible(false); l.setMap(null) });
            vertexCircles.current = [];
        }

    }, [isEditable, shape, parentMap, memoHandleMove, memoHandleClick])//eslint-disable-line react-hooks/exhaustive-deps

    const debouncedUpdate = debounce(() => {
        if (onUpdate && polyLaneLine && shape) {
            onUpdate?.(Object.assign(new LaneLine(), { ...shape, points: polyLaneLine.getPath().getArray().map(fCoordsHelper.fromLatLng) }));
        }
    }, 500);

    usePolygonScaleAndRotation(
        parentMap,
        polyLaneLine,
        () => !!(isEditable && shape && polyLaneLine && isSelected),
        debouncedUpdate,
        () => [vertexCircles.current],
        [polyLaneLine, isEditable, shape, isSelected, vertexCircles]
    );

    const handlePolyDragStart = () => {
        vertexCircles.current.forEach(vc => vc.setVisible(false));
    }

    const handlePolyDragEnd = (e: google.maps.PolyMouseEvent) => {
        const points = polyLaneLine?.getPath().getArray().map(fCoordsHelper.fromLatLng);
        onUpdate?.(Object.assign(new LaneLine(), { ...shape, points: points }));
    }

    useEffect(() => { // handle selection from list
        if (!isSelected || !polyLaneLine || !shape?.isComplete() || !didMountRef.current) { didMountRef.current = true; return; }
        animateCircle(polyLaneLine.getPath().getArray(), parentMap, color ?? DEFAULT_COLOR);
    }, [isSelected, parentMap]);

    useEffect(() => {//selection from map
        if (!isSelectable) { return; }
        let click: google.maps.MapsEventListener;

        if (onClick && parentMap && polyLaneLine && shape?.isComplete()) {
            polyLaneLine.set('clickable', true);
            click = polyLaneLine.addListener('click', (e: google.maps.MapMouseEvent) => onClick?.(e, shape));
        }
        return () => {
            polyLaneLine?.set('clickable', false);
            click?.remove();
        }
    }, [onClick, parentMap, polyLaneLine, shape, isSelectable])
    return null;

    function initPoly(shape: LaneLine, parentMap: google.maps.Map, coordsHelper: ICoordsHelper, color: string) {
        shape.points = populateMissingVectors(shape);
        return createGooglePolyline(shape.points ?? [], coordsHelper, parentMap, { strokeColor: color, icons: [
            {
                icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 4,
                    fillColor: color,
                    fillOpacity: 1,
                    strokeWeight: 1,
                },
            }   
        ], });
    }
    
    
    function populateMissingVectors(shape: LaneLine): Vector2[] {
        if (!shape.points)
            return []
        return shape.points.map(point => point ?? new Vector2());
    }
    
}

