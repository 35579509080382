import { ScreenLine, Vector2 } from "@app/shared";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ICoordsHelper, MapContext, MapObjArray, animateCircle, calculateAngles, createGooglePolyline, createVertexCircle, debounce, useFacilityCoordsHelper } from "../../../../helpers";
import { usePolygonScaleAndRotation } from "../../../../hooks";
import { MapShapeProps } from "../MapShape";

export interface ScreenLineMapShapeProps extends MapShapeProps<ScreenLine> {
    color?: string;
}
export const ScreenLineMapShape: React.FC<ScreenLineMapShapeProps> = ({ facility, shape, isEditable, isSelectable, onUpdate, onClick, isSelected, color }) => {
    const [parentMap, setBounds] = useContext(MapContext);
    const listeners = useRef([] as Array<google.maps.MapsEventListener>);
    const [polyScreenline, setPolyScreenline] = useState<google.maps.Polyline>();
    const [inboundDirectionArrow, setInboundDirectionArrow] = useState<google.maps.Polyline>();
    const [outBoundDirectionArrow, setOutBoundDirectionArrow] = useState<google.maps.Polyline>();

    const mapObjects = useRef([] as MapObjArray);
    const vertexCircles = useRef([] as Array<google.maps.Circle>);
    const didMountRef = useRef(false);
    const fCoordsHelper = useFacilityCoordsHelper(facility);

    useEffect(() => {
        //create/update google poly for shape
        if (parentMap && shape?.points?.length) {
            const [poly] = initPoly(shape, parentMap, fCoordsHelper, color);
            setPolyScreenline(poly);
            setBounds((curr) => {
                poly?.getPath()
                    .getArray()
                    .forEach((pt) => curr.extend(pt));
                return curr;
            });
            return () => {
                poly.setMap(null);
                poly.setVisible(false);
                mapObjects.current?.forEach((mo) => {
                    mo?.setVisible(false);
                    mo?.setMap(null);
                });
                setPolyScreenline(undefined);
                mapObjects.current = [];
            };
        }
    }, [parentMap, shape, color, fCoordsHelper, setBounds]);

    const handleClick = (e: google.maps.MapMouseEvent) => {
        if (!e.latLng || !shape) {
            return;
        }
        const dz = shape;
        const v2 = fCoordsHelper.fromLatLng(e.latLng);
        if (!dz.points?.length) {
            dz.points = [v2];
        } else if (dz.points.length === 1) {
            dz.points.push(v2);
            const { inbound, outbound } = calculateAngles(dz.points[0], dz.points[1], fCoordsHelper, shape.reverseInOut);
            dz.inboundDirection = inbound;
            dz.outboundDirection = outbound;
        } else {
            return;
        }
        onUpdate?.(Object.assign(new ScreenLine(), { ...shape, points: dz.points, inboundDirection: dz.inboundDirection, outboundDirection: dz.outboundDirection }));
    };
    const memoHandleClick = useCallback(handleClick, [shape, onUpdate]); //eslint-disable-line react-hooks/exhaustive-deps

    const handleMove = (e: google.maps.MapMouseEvent) => {
        if (!polyScreenline || !isEditable || !e.latLng || shape?.points?.length === 2) return;

        const path = polyScreenline.getPath().getArray();
        if (shape?.points?.length !== undefined && shape.points.length < path.length) {
            path.pop();
        }

        polyScreenline.setPath([...path, e.latLng]);
        const points = polyScreenline.getPath().getArray().map(fCoordsHelper.fromLatLng);
        drawPerpendicularDirectionArrow(inboundDirectionArrow, outBoundDirectionArrow, points[0], points[1]);
    };
    const memoHandleMove = useCallback(handleMove, [polyScreenline, isEditable, shape]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //editable
        if (isEditable && parentMap) {
            if (!shape?.isComplete()) {
                listeners.current.push(parentMap.addListener("click", memoHandleClick));
                listeners.current.push(parentMap.addListener("mouseout", () => polyScreenline?.setPath(shape?.points?.map(fCoordsHelper.toLatLng) ?? [])));
                listeners.current.push(parentMap.addListener("mousemove", memoHandleMove));
            } else if (polyScreenline) {
                vertexCircles.current = polyScreenline
                    .getPath()
                    .getArray()
                    .map((pos, idx) => {
                        const isClickable = shape?.isComplete() || idx !== 0;
                        const posMark = createVertexCircle(pos, parentMap, isClickable, color ?? "red");
                        posMark.addListener("dragend", (d: google.maps.MapMouseEvent) => {
                            if (d.latLng && polyScreenline) {
                                const path = polyScreenline.getPath();
                                path.setAt(idx, d.latLng);
                                vertexCircles.current[idx]?.setCenter(d.latLng);
                                const points = path.getArray().map(fCoordsHelper.fromLatLng);
                                const angles = calculateAngles(points[0], points[1], fCoordsHelper, shape?.reverseInOut);
                                onUpdate?.(Object.assign(new ScreenLine(), { ...shape, points: points, outboundDirection: angles.outbound, inboundDirection: angles.inbound }));
                                inboundDirectionArrow?.setMap(null);
                                outBoundDirectionArrow?.setMap(null);
                                drawPerpendicularDirectionArrow(inboundDirectionArrow, outBoundDirectionArrow, points[0], points[1]);
                            }
                        });
                        posMark.addListener("drag", (d: google.maps.MapMouseEvent) => {
                            if (d.latLng && polyScreenline) {
                                const path = polyScreenline.getPath();
                                path.setAt(idx, d.latLng);
                                polyScreenline?.setPath(path);
                                const points = path.getArray().map(fCoordsHelper.fromLatLng);
                                inboundDirectionArrow?.setMap(null);
                                outBoundDirectionArrow?.setMap(null);
                                drawPerpendicularDirectionArrow(inboundDirectionArrow, outBoundDirectionArrow, points[0], points[1]);
                            }
                        });

                        return posMark;
                    });
                vertexCircles.current.forEach((vc) => mapObjects.current.push(vc));
                if (shape?.isComplete()) {
                    polyScreenline.setDraggable(true);
                    listeners.current.push(polyScreenline.addListener("dragstart", handlePolyDragStart));
                    listeners.current.push(polyScreenline.addListener("dragend", handlePolyDragEnd));
                }
            }
        }

        return () => {
            listeners.current.forEach((l) => l?.remove());
            listeners.current = [];
            vertexCircles.current.forEach((l) => {
                l.setVisible(false);
                l.setMap(null);
            });
            vertexCircles.current = [];
        };
    }, [isEditable, shape, parentMap, memoHandleMove, memoHandleClick]); //eslint-disable-line react-hooks/exhaustive-deps

    const debouncedUpdate = debounce(() => {
        if (onUpdate && polyScreenline && shape) {
            onUpdate?.(Object.assign(new ScreenLine(), { ...shape, points: polyScreenline.getPath().getArray().map(fCoordsHelper.fromLatLng) }));
        }
    }, 500);

    usePolygonScaleAndRotation(
        parentMap,
        polyScreenline,
        () => !!(isEditable && shape && polyScreenline && isSelected),
        debouncedUpdate,
        () => [vertexCircles.current],
        [polyScreenline, isEditable, shape, isSelected, vertexCircles]
    );

    const handlePolyDragStart = () => {
        vertexCircles.current.forEach((vc) => vc.setVisible(false));
        const points = polyScreenline?.getPath().getArray().map(fCoordsHelper.fromLatLng);
        drawPerpendicularDirectionArrow(inboundDirectionArrow, outBoundDirectionArrow, points![0], points![1]);
    };

    const handlePolyDragEnd = (e: google.maps.PolyMouseEvent) => {
        const points = polyScreenline?.getPath().getArray().map(fCoordsHelper.fromLatLng);
        if (points?.length === 2) {
            const angles = calculateAngles(points[0], points[1], fCoordsHelper, shape?.reverseInOut);
            onUpdate?.(Object.assign(new ScreenLine(), { ...shape, points: points, outboundDirection: angles.outbound, inboundDirection: angles.inbound }));
            drawPerpendicularDirectionArrow(inboundDirectionArrow, outBoundDirectionArrow, points[0], points[1]);
        }
    };

    useEffect(() => {
        // handle selection from list
        if (!isSelected || !polyScreenline || !shape?.isComplete() || !didMountRef.current) {
            didMountRef.current = true;
            return;
        }
        animateCircle(polyScreenline.getPath().getArray(), parentMap, "red");
    }, [isSelected, parentMap]);

    useEffect(() => {
        //selection from map
        if (!isSelectable) {
            return;
        }
        let click: google.maps.MapsEventListener;

        if (onClick && parentMap && polyScreenline && shape?.isComplete()) {
            polyScreenline.set("clickable", true);
            click = polyScreenline.addListener("click", (e: google.maps.MapMouseEvent) => onClick?.(e, shape));
        }
        return () => {
            polyScreenline?.set("clickable", false);
            click?.remove();
        };
    }, [onClick, parentMap, polyScreenline, shape, isSelectable]);

    useEffect(() => {
        if (shape?.isComplete) {
                drawPerpendicularDirectionArrow(inboundDirectionArrow, outBoundDirectionArrow);
                inboundDirectionArrow?.setMap(parentMap || null);
                outBoundDirectionArrow?.setMap(parentMap || null);
        }

        return () => {
            inboundDirectionArrow?.setMap(null);
            outBoundDirectionArrow?.setMap(null);
        };
    }, [shape, polyScreenline]);

    function drawPerpendicularDirectionArrow(
        inboundDirArrow: google.maps.Polyline | undefined,
        outboundDirArrow: google.maps.Polyline | undefined,
        point1: Vector2 | undefined = undefined,
        point2: Vector2 | undefined = undefined
    ): void {
        inboundDirArrow?.setMap(null);
        outboundDirArrow?.setMap(null);
        const firstPoint = point1 || shape?.points![0];
        const secondPoint = point2 || shape?.points![1];
    
        if (!firstPoint || !secondPoint) return;
    
        const coords1 = fCoordsHelper.toLatLng(firstPoint);
        const coords2 = fCoordsHelper.toLatLng(secondPoint);
    
        const arrowLength = 0.00006;
        const toRadians = (deg: number) => (deg * Math.PI) / 180;
    
        const midLat = (coords1.lat() + coords2.lat()) / 2;
        const midLng = (coords1.lng() + coords2.lng()) / 2;
    
        const inbound = shape?.inboundDirection ?? 0
        const outbound = shape?.outboundDirection ?? 0 

        const inboundLat = midLat + arrowLength * Math.cos(toRadians(inbound));
        const inboundLng = midLng + arrowLength * Math.sin(toRadians(inbound));
    
        const outboundLat = midLat + arrowLength * Math.cos(toRadians(outbound));
        const outboundLng = midLng + arrowLength * Math.sin(toRadians(outbound));
    
        const color = "green"; 
    
        const inboundArrow = new google.maps.Polyline({
            path: [new google.maps.LatLng(inboundLat, inboundLng), new google.maps.LatLng(midLat, midLng)],
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 4,
           
        });
    
        const outboundArrow = new google.maps.Polyline({
            path: [new google.maps.LatLng(midLat, midLng), new google.maps.LatLng(outboundLat, outboundLng)],
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 4,
            icons: [
                {
                    icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 4,
                        fillColor: color,
                        fillOpacity: 1,
                        strokeWeight: 1,
                    },
                    offset: "100%",
                },
            ],
        });
    
        setInboundDirectionArrow(inboundArrow);
        setOutBoundDirectionArrow(outboundArrow);
    }
    return null;
};

function initPoly(shape: ScreenLine, parentMap: google.maps.Map, coordsHelper: ICoordsHelper, color?: string) {
    shape.points = populateMissingVectors(shape);
    return createGooglePolyline(shape.points ?? [], coordsHelper, parentMap, { strokeColor: color });
}

function populateMissingVectors(shape: ScreenLine): Vector2[] {
    if (!shape.points) return [];
    return shape.points.map((point) => point ?? new Vector2());
}
