/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiResult, get, del } from "./api_client";
import { CacheDetails, ICacheDetails } from "../models";
/* eslint-enable @typescript-eslint/no-unused-vars */

export async function getAll(): ApiResult<CacheDetails> {
	return get<ICacheDetails>(`/api/cache`).then(result => result === undefined ? undefined :  new CacheDetails(result));
}

export async function flushCache(): ApiResult<CacheDetails> {
	return del<ICacheDetails>(`/api/cache`).then(result => result === undefined ? undefined :  new CacheDetails(result));
}

export async function flushCacheEntry(key?: string): ApiResult<CacheDetails> {
	return del<ICacheDetails>(`/api/cache/${ key }`).then(result => result === undefined ? undefined :  new CacheDetails(result));
}
