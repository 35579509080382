import { Device, Errors, useAuth } from "@app/shared";
import { useConfirmDialog, useFetch } from "../../../hooks";
import { DataInfoCard, Icons, Link, PageContainer, SortableTable } from "../../shared";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Pages } from "..";

export const DeviceErrorsTable: React.FC<{ device?: Device }> = (props) => {
    const [deviceErrors, , deviceErrorsHelpers] = useFetch(() => props.device ? Errors.getDeviceErrors(props?.device!.id) : Errors.getAllDeviceErrors(), [props.device]);
    const { numberFormatter } = useAuth();
    const navigate = useNavigate();
    const confirm = useConfirmDialog();
    const handleDetails = (stack: string) => {
        confirm.show("Stack Trace", stack, "OK", _ => { });
    }
    const gotoDeviceErrors = (deviceId: string) => {
        navigate(Pages.DeviceDetails.getUrlWithQuery({ id: deviceId }, { tab: '6' }));//6 is currently errors
    }
    return (
        <>
            {props.device && <div className="p-5 m-5"> </div>}
            {deviceErrors?.length && <div className="md:flex gap-4 flex">
                <DataInfoCard Icon={Icons.Filter} title="Entries">{numberFormatter.format(deviceErrors?.length ?? 0)}</DataInfoCard>
                <DataInfoCard Icon={Icons.Expand} title="Occurrences">{numberFormatter.format(deviceErrors?.reduce((total, x) => x.count + total, 0) ?? 0)}</DataInfoCard>
                <DataInfoCard Icon={Icons.Success} title="Devices">{props.device ? props.device?.name : numberFormatter.format(new Set(deviceErrors?.flatMap(x => x.deviceIds)).size ?? 0)}</DataInfoCard>
            </div>}

            <PageContainer title="Recent Errors">
                <SortableTable isLoading={deviceErrorsHelpers.isLoading} columns={[{
                    header: "Key",
                    dataKey: "hashId",
                    renderFunc: (data) => (<div className="w-100 break-words">{data?.hashId}</div>),
                    width: 80,
                }, {
                    header: "Last Occurrence",
                    dataKey: "lastOccurrence",
                    renderFunc: (data) => (<div className="w-100 break-words">{data?.lastOccurrence ? format(data?.lastOccurrence!, 'MMM d, yyyy HH:mm:ss') : ''}</div>),
                    width: 60,
                }, {
                    header: "Occurrences",
                    dataKey: "count",
                    width: 60,
                }, {
                    header: "Message",
                    dataKey: "details",
                    renderFunc: (data) => (<div className="w-100 break-words">{data?.details?.details}</div>),
                    width: 300,
                },
                {
                    header: "Devices",
                    renderFunc: (data) => (<ul>{data?.deviceIds?.map(did => (<li key={did}><Link onClick={() => gotoDeviceErrors(did)}>{did}</Link></li>))}</ul>),
                    width: 100,
                }, {
                    header: "",
                    renderFunc: (fe) => (fe?.details?.stackTrace?.length) ?
                        <Link onClick={() => handleDetails(fe.details!.stackTrace!)}>View Stack</Link>
                        : "",
                    width: 50,
                }]} data={deviceErrors?.map(de => ({ ...de, id: de.hashId }))}
                    initialSortKey="lastOccurrence"
                    initialSortDir="desc" />
            </PageContainer>
            {confirm.renderDialog()}
        </>
    );
}