import { AggregatedTargetAttribute, AggregationPeriod, ApiResult, AverageDailyVolume, Reports, TrafficReportConfiguration, useAuth } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { useContext } from "react";
import { useFetch } from "../../hooks";
import { ReportContext, colorMap, getGroupingDisplayName, getZonedDateAsUTC } from "./reportHelpers";
import { Bar, ChartProps, Line } from 'react-chartjs-2';
import { Chart, registerables, TooltipItem } from 'chart.js';

import { AverageDailyVolumeByMonth } from "./AverageDailyVolumeByMonth";
Chart.register(...registerables);

export const AnnualDailyVolumeReport: React.FC<TrafficReportProps & { data: AverageDailyVolume[], labelFunc?: (year: number) => string }> = props => {
    const { reportConfig } = useContext(ReportContext);
    const { chartType, grouping } = reportConfig;
    const { numberFormatter } = useAuth();
    const { data, title, subTitle } = props;
    if (!data.length) {
        throw new Error('must data to use chart');
    }

    const labels = Array.from(new Set(data.map((d) => d.year))).map(y => props.labelFunc ? props.labelFunc(y) : y);
    let datasets = []
    if (grouping === AggregatedTargetAttribute.None) {
        datasets = [{
            label: 'Targets',
            data: data.map(x => x.avgVolume),
            backgroundColor: colorMap[grouping][0]
        }];
    } else {
        const stacked = data.reduce<Map<number, AverageDailyVolume[]>>((acc, item) => {
            if (!acc.has(item.attributeValue!)) {
                acc.set(item.attributeValue!, []);
            }
            acc.get(item.attributeValue!)!.push(item);
            return acc;
        }, new Map()).values();
        datasets = stacked.map((s, idx) => ({
            label: getGroupingDisplayName(grouping, s[0].attributeValue!),
            data: s.map(x => x.avgVolume),
            backgroundColor: colorMap[grouping][s[0].attributeValue!]
        })).toArray();
    }

    const chartjsProps = {
        data: {
            labels: labels,
            datasets
        },
        options: {
            onClick: (evt, elements) => {
                const year = data[elements[0].index].year;
                const rParams = { ...reportConfig };
                rParams.selectedReportId = AverageDailyVolumeByMonth.key;
                rParams.inclusiveStart = getZonedDateAsUTC(year, 1, 1, reportConfig.chartTimezone);
                rParams.exclusiveEnd = getZonedDateAsUTC((year) + 1, 1, 1, reportConfig.chartTimezone);
                reportConfig.context.setReportConfig(rParams);
            },
            plugins: {
                title: {
                    display: !!title,
                    text: !!!title ? undefined : typeof title !== 'string' ? title(data) : title,
                    font: {
                        size: 24, // Font size for the main title
                        weight: 'bold'
                    },
                },
                subtitle: {
                    display: !!subTitle,
                    text: !!!subTitle ? undefined : typeof subTitle !== 'string' ? subTitle(data) : subTitle,
                    font: {
                        size: 16, // Font size for the main title
                        weight: 'bold'
                    },
                },
                legend: {
                    display: grouping !== AggregatedTargetAttribute.None,
                    position: 'bottom',
                    labels: {
                        filter: (legendItem, chartData) => {
                            const datasetIndex = legendItem.datasetIndex;
                            if (datasetIndex === undefined || !chartData?.datasets) return false;

                            // Check if the dataset has any non-zero values
                            return chartData.datasets[datasetIndex].data.some(value => value !== 0);
                        }
                    }
                },
                tooltip: {
                    filter: (tooltipItem: TooltipItem<'bar'>) => {
                        return !Number.isNaN(tooltipItem.raw) && tooltipItem.raw !== 0; // Exclude zero values
                    },
                    callbacks: {
                        footer: (tooltipItems: TooltipItem<'bar'>[]) => {
                            const total = tooltipItems.reduce((sum, tooltipItem) => {
                                return sum + (tooltipItem.raw as number);
                            }, 0);
                            return [`Total: ${numberFormatter.format(total)}`,
                            grouping === AggregatedTargetAttribute.None ?
                                `(based on ${data[tooltipItems[0].dataIndex].numDaysIncluded} days with data)` :
                                `(based on ${data.find(d => (d.year + '') === tooltipItems[0].label)?.numDaysIncluded} days with data)`];
                        }
                    },
                },
            },
            interaction: {
                mode: 'index', // Ensures all datasets for the same label are shown
                intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
            },
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: chartType === 'bar'
                },
                x: {
                    stacked: true
                }
            }
        }
    } as Omit<ChartProps<"line" | "bar", (number | [number, number] | null)[], unknown>, "type">;//), [grouping, numberFormatter, datasets, years]);

    return chartType === 'line' ?
        <Line  {...chartjsProps as ChartProps<'line'>} width={props.size.width} height={props.size.height} ></Line> :
        <Bar  {...chartjsProps as ChartProps<'bar'>} width={props.size.width} height={props.size.height} ></Bar>;
}

export interface AverageDailyVolumeByYearLoaderProps extends TrafficReportProps {
    dataToChart?: (props: TrafficReportConfiguration) => ApiResult<AverageDailyVolume[]>;
    labelFunc?: (year: number) => string
}

export const AverageDailyVolumeByYearLoader: React.FC<AverageDailyVolumeByYearLoaderProps> = props => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => props.dataToChart ? props.dataToChart(searchParams) : Reports.getAverageDailyVolumes(reportConfig.grouping, AggregationPeriod.Year, searchParams), [searchParams, props.dataToChart]);

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        <AnnualDailyVolumeReport {...props} data={data!} labelFunc={props.labelFunc} />
    </ReportLoadingErrorWrapper>);
}

export const AverageDailyVolumeByYear: IConfiguredTrafficReport = {
    name: "by Year",
    key: "advy",
    description: "This report shows average daily traffic for the specified period",
    component: AverageDailyVolumeByYearLoader,
    defaultChartType: 'bar',
    defaultRange: DateOptions.AllData,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};