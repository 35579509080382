import { CustomClaimValue, Device, Facilities, Facility, useAuth, useIsMounted } from "@app/shared";
import { useFetch } from ".";
import { AuthorizeView } from "../components/shared/AuthorizeView";
import { InputRow, Select, SelectOption } from "../components/shared/inputs";
import { useRef, useState } from "react";
import { Dialog } from "../components/shared/Dialog";


type OkClickHandlerType = (oldFacility: Facility | null, newFacility: Facility | null) => Promise<void | boolean> | void | boolean;

export function useChangeLocationDialog() {
    const isMounted = useIsMounted();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedFacilityId, setSelectedFacilityId] = useState(undefined as string | undefined);
    const [initialFacilityId, setInitialFacilityId] = useState(undefined as string | undefined);
    const { currentOrganizationId } = useAuth();
    const [facilities] = useFetch(() => Facilities.getByOrganizationId(currentOrganizationId), [currentOrganizationId]);


    var onOkClickHandler = useRef<OkClickHandlerType>();

    const show = (device: Device, _okClickHandler: OkClickHandlerType, onClose?: () => void) => {
        setSelectedFacilityId(device.facilityId);
        setInitialFacilityId(device.facilityId);
        setShowDialog(true);
        onOkClickHandler.current = _okClickHandler;
    }

    const handleOkClick = async () => {
        //await modelHelpers.validateWithAction(async () => {
        if (onOkClickHandler && onOkClickHandler.current && selectedFacilityId !== undefined) {
            const doNotClose = await onOkClickHandler.current(facilities?.find(x => x.id === initialFacilityId)!, selectedFacilityId === 'none' ? null : facilities?.find(x => x.id === selectedFacilityId)!);
            if (doNotClose) return;
        }
        if (isMounted.current) {
            setShowDialog(false);
        }
        //});
    }
    const renderDialog = () => {

        return (<>
            <Dialog title="Change Device Location" show={showDialog} onOkClick={handleOkClick} onCancelClick={() => setShowDialog(false)} closeOnOutsideClick={true} okButtonText="Change" disableOkButton={selectedFacilityId === initialFacilityId}>
                <span >
                    <AuthorizeView claim={CustomClaimValue.EditDetails}>
                        <InputRow><Select label="Location" options={[{ value: 'none', label: 'None' }, ...facilities?.map(f => ({ value: f.id, label: f.name } as SelectOption)) ?? []]} value={selectedFacilityId} onChange={x => setSelectedFacilityId(x.target.value)} /></InputRow>
                    </AuthorizeView>

                </span>
            </Dialog>
        </>
        );
    }
    return { renderDialog, selectedFacilityId, show }
}