import { Guid, IGuid } from "./System.Guid.gen";
import { WebRelayModel } from "./Shared.Models.Api.V1.ClientEndpoints.WebRelayModel.gen";
import { EndpointStatus } from "./ClientEndpoints.EndpointStatus.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, IsOptional } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IWebRelayClientEndpoint {
	model: WebRelayModel;

	ipAddress: string | undefined;

	password: string | undefined;

	description: string | undefined;

	status: EndpointStatus;

	facilityName: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	organizationId: string | undefined;

	endpointName: string;

	enableHeartbeat: boolean;

	secondsBetweenHearbeat: number;

	$type?: string | undefined;
}
export class WebRelayClientEndpoint {
	constructor(data?: IWebRelayClientEndpoint) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	model: WebRelayModel = 0;

	ipAddress: string | undefined;

	password: string | undefined;

	description: string | undefined;

	status: EndpointStatus = 0;

	facilityName: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	organizationId: string | undefined;

	@IsNotEmpty()
	endpointName: string = "";

	enableHeartbeat: boolean = false;

	secondsBetweenHearbeat: number = 0;

	static $type: string = "CoreApi.Models.ClientEndpoints.WebRelayClientEndpoint, ";
	$type?: string | undefined = WebRelayClientEndpoint.$type;
}

