export interface IServerErrorSummary {
	hashId: number;

	total: number;

	hourlyAverage: number;

	traceId: string | undefined;

	details: string | undefined;

	lastOccurrence: string | undefined;

	$type?: string | undefined;
}
export class ServerErrorSummary {
	constructor(data?: IServerErrorSummary) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.lastOccurrence) { this.lastOccurrence = new Date(data.lastOccurrence); }
	}

	hashId: number = 0;

	total: number = 0;

	hourlyAverage: number = 0;

	traceId: string | undefined;

	details: string | undefined;

	lastOccurrence: Date | undefined = new Date();

	static $type: string = "CoreApi.Models.ServerErrorSummary, ";
	$type?: string | undefined = ServerErrorSummary.$type;
}

