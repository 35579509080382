import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { extLightThemeColors } from "../../../styles/chartThemeColors";
import { ChartData, TooltipItem } from "chart.js";


export type HardwareStatusChartProps<T> = {
    id?: string;
    title?: string;
    data: T[] | undefined;
    series: SeriesDetails<T>[];
    isLoading?: boolean;
    yUnit?: string;
    domain?: [number, number];
} & ChartSizeProps;

export type ChartSizeProps = {
    width?: number;
    height?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
}

export type SeriesAccessors<T> = {
    date: (data?: T) => Date | undefined;
    xAccessor: (data?: T) => Date | undefined;
    yAccessor: (data?: T) => number | undefined;
}

export type SeriesDetails<T> = {
    dataKey: string;
    accessors: SeriesAccessors<T>;
    opacity?: number;
}

export function HardwareStatusChart<T extends object>({ id, title, data, series, isLoading, width, height = 300, domain, margin = { top: 10, right: 25, bottom: 50, left: 50 }, yUnit }: HardwareStatusChartProps<T>) {
    const datasets: ChartData<'line'>['datasets'] = [...series.map((s, sIdx) =>
    ({
        label: title,
        data: data?.map(d => ({ x: s.accessors.xAccessor(d)! as any, y: s.accessors.yAccessor(d)! })) || [],
        borderColor: extLightThemeColors[sIdx % extLightThemeColors.length],
        backgroundColor: extLightThemeColors[sIdx % extLightThemeColors.length],
        pointRadius: 0,
    }))
    ];


    const chartjsProps = {
        data: {
            datasets
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem:TooltipItem<"line">) {
                            return `${series[tooltipItem.datasetIndex]?.dataKey?.padEnd(10,' ')} ${Math.round(tooltipItem.parsed.y)} ${yUnit}`; // Round & append '%'
                        }
                    }
                }
            },
            animation: {
                duration: 500 // Smooth transition when data loads
            },
            interaction: {
                mode: 'index', // Ensures all datasets for the same label are shown
                intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
            },
            scales: {
                y: {
                    beginAtZero: true
                },
                x: {
                    type: "time", // Enables automatic time-based scaling
                    time: {
                        tooltipFormat: "PPpp", // Formats tooltip using date-fns style
                    },
                    ticks: {
                        source: "auto",
                        autoSkip: true, // Prevents cluttered ticks
                        maxTicksLimit: 10, // Adjust this as needed
                    }
                }
            }
        }
    };

    return (<>
        <div className="w-full p-4" style={{ height: '330px' }}>
            <h2 className="text-center w-full pb-0 mb-0">{title}</h2><Line className="m-3" {...chartjsProps as any} width={width} height={height} ></Line>
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
                    <p className="text-gray-500 text-lg">Loading...</p>
                </div>
            )}
        </div>
    </>);
}
