import { ScreenLine, Vector2 } from "../models";

declare module "../models/ScreenLine.gen" {
    interface ScreenLine {
        clampPointIfClosing(point: Vector2, threshold: number): Vector2;
        isComplete(): boolean;
        setReverseInOut(bool: boolean) : void
    }
}
ScreenLine.prototype.isComplete = function () {
    return this.points?.length === 2;
};
ScreenLine.prototype.clampPointIfClosing = function (point: Vector2, threshold: number) {
    return point;
};

ScreenLine.prototype.setReverseInOut = function (bool) {
    this.reverseInOut = bool;
}
