import { TrafficReportProps } from "./IConfiguredTrafficReport";
import { Reports, TotalVolumeByPeriod, AggregationPeriod, AggregatedTargetAttribute, useAuth } from "@app/shared";
import { useContext, useEffect, useMemo } from "react";
import { colorMap, getDateFormat, getGroupingDisplayName, ReportContext } from "./reportHelpers";
import { Chart, registerables, TooltipItem } from 'chart.js';
import { useFetch } from "../../hooks";
import { formatInTimeZone } from "date-fns-tz";
import { Bar, ChartProps, Line } from "react-chartjs-2";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
Chart.register(...registerables);

export interface TotalVolumeDataLoaderProps extends TrafficReportProps {
    aggregationPeriod: AggregationPeriod,
    onClick?: (date: Date) => void
}
export interface TotalVolumeChartProps extends TrafficReportProps {
    aggregationPeriod: AggregationPeriod,
    grouping: AggregatedTargetAttribute,
    data: TotalVolumeByPeriod[],
    startDate: Date,
    endDate: Date,
    dateFormat?: string,
    chartTimezone: string,
    chartType: 'bar' | 'line'
    onClick?: (date: Date) => void
}
export const TotalVolumeDataLoader: React.FC<TotalVolumeDataLoaderProps> = (props) => {
    const { searchParams, reportConfig, setIsLoading } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(reportConfig.grouping, props.aggregationPeriod, searchParams), [searchParams]);
    useEffect(() => setIsLoading(isLoading), [isLoading, setIsLoading]);
    if (reportConfig.chartType === 'other') {
        throw new Error('unsupported chart type for total volume');
    }
    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        <TotalVolumeChart {...props} grouping={reportConfig.grouping} data={data!} startDate={searchParams.inclusiveStart} endDate={searchParams.exclusiveEnd} chartTimezone={reportConfig.chartTimezone} chartType={reportConfig.chartType} />
    </ReportLoadingErrorWrapper>);
}

export const TotalVolumeChart: React.FC<TotalVolumeChartProps> = (reportProps) => {
    const { aggregationPeriod, size, data, grouping, chartTimezone, chartType, title, subTitle } = reportProps;
    const { numberFormatter } = useAuth();
    const sortedData = useMemo(() => [...data].sort((a, b) => a.periodStart!.getTime() - b.periodStart!.getTime()), [data])
    const xValues = useMemo(() => Array.from(new Set(sortedData.map(item => item.periodStart!.getTime()) ?? [])).map(x => new Date(x)), [sortedData]);

    const dateFormat = useMemo(() => {
        if (reportProps.dateFormat) {
            return reportProps.dateFormat;
        }
        if (!xValues.length) {
            return 'yyyy';
        }

        return getDateFormat(chartTimezone, aggregationPeriod, reportProps.startDate, reportProps.endDate);
    }, [xValues, aggregationPeriod, chartTimezone, reportProps.dateFormat, reportProps.startDate, reportProps.endDate]);

    let datasets = []
    if (grouping === AggregatedTargetAttribute.None) {
        datasets = [{
            label: 'Targets',
            data: sortedData.map(x => x.count),
            backgroundColor: colorMap[grouping][0]
        }];
    } else {
        const stacked = sortedData.reduce<Map<number, TotalVolumeByPeriod[]>>((acc, item) => {
            if (!acc.has(item.attributeValue!)) {
                acc.set(item.attributeValue!, []);
            }
            acc.get(item.attributeValue!)!.push(item);
            return acc;
        }, new Map()).values();
        datasets = stacked.map((s, idx) => ({
            label: getGroupingDisplayName(grouping, s[0].attributeValue!),
            data: xValues.map(x => s.find(atCount => atCount.periodStart?.getTime() === x.getTime())?.count ?? NaN),
            backgroundColor: colorMap[grouping][s[0].attributeValue]
        })).toArray();
    }

    const chartjsProps = {
        data: {
            labels: xValues.map(x => formatInTimeZone(x, chartTimezone, dateFormat)),
            datasets
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            // onClick: (evt, elements) => {
            //     const year = data[elements[0].index].year;
            //     const rParams = { ...reportConfig };
            //     rParams.selectedReportId = AverageDailyVolumeByMonth.key;
            //     rParams.inclusiveStart = getZonedDateAsUTC(year, 1, 1, reportConfig.chartTimezone);
            //     rParams.exclusiveEnd = getZonedDateAsUTC((year) + 1, 1, 1, reportConfig.chartTimezone);
            //     reportConfig.context.setReportConfig(rParams);
            // },
            plugins: {
                title: {
                    display: !!title,
                    text: !!!title ? undefined : typeof title !== 'string' ? title(sortedData) : title,
                    font: {
                        size: 24, // Font size for the main title
                        weight: 'bold'
                    },
                },
                subtitle: {
                    display: !!subTitle,
                    text: !!!subTitle ? undefined : typeof subTitle !== 'string' ? subTitle(sortedData) : subTitle,
                    font: {
                        size: 16, // Font size for the main title
                        weight: 'bold'
                    },
                },
                legend: {
                    display: grouping !== AggregatedTargetAttribute.None,
                    position: 'bottom',
                    labels: {
                        filter: (legendItem, chartData) => {
                            const datasetIndex = legendItem.datasetIndex;
                            if (datasetIndex === undefined || !chartData?.datasets) return false;
        
                            // Check if the dataset has any non-zero values
                            return chartData.datasets[datasetIndex].data.some(value => value !== 0);
                        }
                    }
                },
                tooltip: {
                    filter: (tooltipItem: TooltipItem<'bar'>) => {
                        return !Number.isNaN(tooltipItem.raw) && tooltipItem.raw !== 0; // Exclude zero values
                    },
                    callbacks: {
                        footer: (tooltipItems: TooltipItem<'bar'>[]) => {
                            if (grouping === AggregatedTargetAttribute.None) {
                                return null;
                            }
                            const total = tooltipItems.reduce((sum, tooltipItem) => {
                                return sum + (tooltipItem.raw as number || 0)
                            }, 0);
                            return `Total: ${numberFormatter.format(total)}`;
                        }
                    }
                },
            },
            interaction: {
                mode: 'index', // Ensures all datasets for the same label are shown
                intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
            },
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: chartType === 'bar'
                },
                x: {
                    stacked: true,
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 31,
                    }
                }
            }
        }
    } as Omit<ChartProps<"line" | "bar", (number | [number, number] | null)[], unknown>, "type">;//), [grouping, numberFormatter, datasets, years]);

    return chartType === 'line' ?
        <Line  {...chartjsProps as ChartProps<'line'>} width={size.width} height={size.height} ></Line> :
        <Bar  {...chartjsProps as ChartProps<'bar'>} width={size.width} height={size.height} ></Bar>
}
// function createWrappedMap<T, U>(array1: T[], array2: U[]): Map<T, U> {
//     const map = new Map<T, U>();
//     for (let i = 0; i < array1.length; i++) {
//         const wrappedIndex = i % array2.length;
//         map.set(array1[i], array2[wrappedIndex]);
//     }
//     return map;
// }