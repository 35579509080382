import { FC } from "react";
import { TabbedDashLayout } from "../../layouts/TabbedDashLayout";
import { GlobalDevices } from "./GlobalDevices";
import { ManageFirmware } from "./ManageFirmware";
import { Jobs } from "./Jobs";
import { ServerCache } from "./ServerCache";
import { ErrorsView } from "./ErrorsView";


export const Settings: FC = (props) => {
    return (
        <TabbedDashLayout centerChildren="Site Admin" tabs={[
            { title: "Firmware", child: <ManageFirmware /> },
            { title: "Devices", child: <GlobalDevices /> },
            { title: "Jobs", child: <Jobs /> },
            { title: "Cache", child: <ServerCache /> },
            { title: "Errors", child: <ErrorsView /> }
        ]} />
    );
}