import { Facilities, Facility, ScreenLine } from "@app/shared";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Pages } from "..";
import { useFetch } from "../../../hooks";
import { DashboardLayout } from "../../layouts";
import { TabbedDashLayout } from "../../layouts/TabbedDashLayout";
import { ReportsView } from "../../reports/ReportsView";
import { SkeletonGrid } from "../../shared";
import { RequiresGoogleMaps } from "../../shared/maps/RequiresGoogleMaps";
import { FacilityDetailsInfo } from "./FacilityDetailsInfo";
import { FacilityDevices } from "./FacilityDevices";
import { FacilityEvents } from "./FacilityEvents";
import { FacilityIntegrations } from "./FacilityIntegrations";
import { FacilityLayoutInfo } from "./FacilityLayoutInfo";
import { FacilityLiveView } from "./FacilityLiveView";
import { FacilityParkingView } from "./FacilityParkingView";

export const FacilityDetails: FC = (props) => {
    const { id } = useParams();
    const [facility, , helpers] = useFetch(() => Facilities.getById(id));
    const navigate = useNavigate();

    useEffect(() => {
        if ((!facility || !facility.id) && helpers.isLoading === false) {
            console.log("Facility not found, redirecting to facility groups");
            navigate(Pages.ViewFacilityGroups.getUrl());
        } else {
            defaultShapePoints(facility)
        }
    }, [facility, helpers.isLoading, navigate])

    const defaultShapePoints = (facility: Facility | undefined) => {
        if (!facility) return;
        FixNanDefaultFacilityScreenLines(facility.screenLines ?? [])
    }

    const FixNanDefaultFacilityScreenLines = (screenLines: ScreenLine[]) => {
        screenLines.forEach(s => {
            if (s.points) {
                s.points = s.points.filter(p => typeof p.x === 'number' && !isNaN(p.x) && typeof p.y === 'number' && !isNaN(p.y));
            }
        });
    };


    if (helpers.isLoading || helpers.isLoading === undefined) {
        return (
            <DashboardLayout centerChildren={"Loading..."}>
                <SkeletonGrid rows={3} cols={1} />
            </DashboardLayout>
        );
    }

    if (!facility)
        return <></>;

    var key = 1;

    return (
        <TabbedDashLayout
            tabs={[
                { title: "Details", child: <FacilityDetailsInfo key={key++} facility={facility} onChange={helpers.refreshData} /> },
                { title: "Recent Targets", child: <RequiresGoogleMaps><FacilityLiveView key={key++} facility={facility} /></RequiresGoogleMaps> },
                { title: "Parking View", child: <RequiresGoogleMaps><FacilityParkingView key={key++} facility={facility} /></RequiresGoogleMaps> },
                { title: "Layout", child: <RequiresGoogleMaps><FacilityLayoutInfo key={key++} facility={facility} onChange={helpers.refreshData} /></RequiresGoogleMaps> },
                { title: "Devices", child: <FacilityDevices key={key++} facility={facility} onChange={helpers.refreshData} /> },
                { title: "Reports", child: <ReportsView key={key++} facility={facility} onChange={helpers.refreshData} /> },
                { title: "Integrations", child: <FacilityIntegrations key={key++} facility={facility} onChange={helpers.refreshData} /> },
                { title: "Events", child: <RequiresGoogleMaps><FacilityEvents key={key++} facility={facility} onChange={helpers.refreshData} /></RequiresGoogleMaps> }]}
            centerChildren={facility?.name}
        />
    );
}