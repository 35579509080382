import { DetectionZone } from "@app/shared";
import { Input, InputRow, NumericInput } from "../../inputs";

type DetectionZoneConfigProps = {
    currentZone: DetectionZone;
    onChange: (zone: DetectionZone) => void;
    isEditable?: boolean;
    isCollapsed: boolean;
    cantDeleteReason: string | undefined;
};

export const DetectionZoneConfig: React.FC<DetectionZoneConfigProps> = ({ currentZone, onChange, isCollapsed, isEditable = false, cantDeleteReason }) => {
    const handleOnChange = (key: keyof DetectionZone, value: DetectionZone[keyof DetectionZone]) => {
        onChange(new DetectionZone({ ...currentZone, [key]: value }));
    }
    if (isCollapsed) { return null; }
    return (
        <>
            <InputRow label="Detection Zone Type">
                <Input onChange={(e) => handleOnChange("name", e.target.value)} label="Name" value={currentZone.name} disabled={!isEditable} />
            </InputRow>
            <InputRow>
                <NumericInput label="Zone Index" onChange={(e) => handleOnChange("zoneIndex", e)} value={currentZone.zoneIndex} disabled={!isEditable} />
            </InputRow>
            <p className="text-center font-medium text-sm mt-4" style={{ color: "red" }}>
                {cantDeleteReason}
            </p>
        </>
    );
}
