import { Caches, useAuth } from "@app/shared";
import { useConfirmDialog, useFetch } from "../../../hooks";
import { DataInfoCard, IconLink, Icons, PageContainer, SortableTable } from "../../shared";
import { HoverTooltip } from "../../shared/HoverTooltip";

export const ServerCache: React.FC = () => {
    const [cacheDetails, , cacheDetailsHelpers] = useFetch(() => Caches.getAll());
    const { numberFormatter } = useAuth();


    const confirm = useConfirmDialog();
    async function handleDeleteOnClick(key: string | undefined) {
        await Caches.flushCacheEntry(key);
        await cacheDetailsHelpers.refreshData();
    }
    async function handleDeleteAll() {
        confirm.show("Purge Cache", `Are you sure you want to delete all cache entries`, "Delete", async () => {
            await Caches.flushCache();
            await cacheDetailsHelpers.refreshData();
        });

    }

    const formatSize = (byteCount: number) => {
        if (byteCount === 0) return "0 Bytes";
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(byteCount) / Math.log(k));
        return `${parseFloat((byteCount / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    }

    return (
        <>
            {cacheDetails?.stats && <div className="md:flex gap-4 flex">
                <DataInfoCard Icon={Icons.Filter} title="Entries">{numberFormatter.format(cacheDetails.stats.currentEntryCount)}</DataInfoCard>
                <DataInfoCard Icon={Icons.Expand} title="Size (estimated)">{formatSize(cacheDetails.stats.currentEstimatedSize ?? 0)}</DataInfoCard>
                <DataInfoCard Icon={Icons.Success} title="Hits">{numberFormatter.format(cacheDetails.stats.totalHits)}</DataInfoCard>
                <DataInfoCard Icon={Icons.Warning} title="Misses">{numberFormatter.format(cacheDetails.stats.totalMisses)}</DataInfoCard>
            </div>}

            <PageContainer title="CoreApi Cache" titleRight={<IconLink Icon={Icons.Trash} onClick={handleDeleteAll}>Purge All Entries</IconLink>}>
                <SortableTable
                    columns={[
                        {
                            header: "Key",
                            dataKey: "key",
                            width: 100,
                        },
                        {
                            header: "Level",
                            dataKey: "isL2",
                            width: 100,
                            renderFunc: (f) => (<HoverTooltip hoverable={f?.isL2 ? "L2" : "L1"} placement="right" offset={[-27, 0]}  >
                                <p>L1: In process memory cache on web server</p>
                            </HoverTooltip>)
                        },
                        {
                            header: "Server Name",
                            dataKey: "serverName",
                            width: 100,
                        },
                        {
                            header: "",
                            width: 25,
                            renderFunc: (f) => (
                                <IconLink Icon={Icons.Trash} onClick={() => handleDeleteOnClick(f?.id)}>Remove</IconLink>
                            )
                        }

                    ]}
                    data={cacheDetails?.keys?.map(x => ({ id: x.key, ...x }))} />
            </PageContainer>
            {confirm.renderDialog()}
        </>
    );
}