import { DetectionZone, Device, Facilities, Facility, ScreenLine, Target, Targets, Vector2 } from "@app/shared";
import { add } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import _ from "lodash";
import { ChangeEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { useSessionStorage } from "react-use";
import { toastWarning, useFacilityCoordsHelper } from "../../../helpers";
import { useFacilityCenter } from "../../../helpers/mapHelpers";
import { useDialog, useFetch } from "../../../hooks";
import { useLiveViewHub } from "../../../hooks/useLiveViewHub";
import { CheckboxInput, DateInput, Icons, PageContainer, Select, SelectOption } from "../../shared";
import { TargetMapShape } from "../../shared/maps";
import { MapShape } from "../../shared/maps/MapShape";
import { OmniGoogleMap } from "../../shared/maps/OmniGoogleMap";
import { RecentTargetsTable } from "./RecentTargetsTable";
import { TargetDetailsDialog } from "./TargetDetailsDialog";

type FacilityLiveViewProps = {
    facility: Facility;
};

const timeOptions: SelectOption[] = [
    { label: "1 Hours", value: 1 },
    { label: "4 Hours", value: 4 },
    { label: "8 Hours", value: 8 },
    { label: "12 Hours", value: 12 },
    { label: "24 Hours", value: 24 },
    { label: "Date Range", value: -1 }
];

export const FacilityLiveView: React.FC<FacilityLiveViewProps> = ({ facility }) => {
    const [hoursToShow, setHoursToShow] = useState(1);
    const [hideOverflow, setHideOverflow] = useState(false);

    const [selectedTargets, setSelectedTargets] = useState<{ [key: string]: Target }>({});
    const [dateRange, setDateRange] = useState<[Date?, Date?]>([add(new Date(), { hours: -hoursToShow }), undefined]);
    const [recentTargetsFromServer, errors, targetsHelper] = useFetch(() => Facilities.getTargets(facility.id, dateRange[0]?.getTime() ?? 0, dateRange[1]?.getTime() ?? 0), [dateRange, facility]);
    const [allTargets, setAllTargets] = useState(recentTargetsFromServer);
    const details = useDialog<Target>();
    const [centerCoords, isLoading] = useFacilityCenter(facility);
    const [autoSelect, setAutoSelect] = useState(true);
    const autoSelectRef = useRef(autoSelect);
    const coordsHelper = useFacilityCoordsHelper(facility);
    const [hideDevices, setHideDevices] = useSessionStorage(`facility_liveview_hidedevices_${facility.id}`, false);
    if (!facility.localTimezoneId) {
        timeOptions.pop();
    }


    //Connect our live view
    useLiveViewHub(facility.id, (targets: Target[]) => {
        if (autoSelectRef.current) {
            setSelectedTargets((prev) => {
                const newSelections = { ...prev };
                targets.forEach(target => {
                    newSelections[target.id!] = { ...target, history: ([...target?.history ?? [], ...prev[target.id!]?.history ?? []]).sort((a, b) => b.date!.getTime() - a.date!.getTime()) };
                });
                return newSelections;
            });
        }
        //Add or update this target in our master list
        setAllTargets(prev => ([...targets, ...(prev?.filter(t => !targets.find(newT => t.id === newT.id)) ?? [])]));
    }, !!dateRange[1]);

    useEffect(() => {
        autoSelectRef.current = autoSelect;
    }, [autoSelect])

    //Keep our local state updated from our fetch
    useEffect(() => {
        setAllTargets(recentTargetsFromServer);
        setSelectedTargets({});
    }, [recentTargetsFromServer]);

    const handleOnTimeOptionChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        var newVal = parseInt(e.target.value);
        setHoursToShow(newVal);
        if (newVal <= 0) {
            //Show custom date range

        } else {
            setDateRange([add(new Date(), { hours: -newVal }), undefined]);
        }
    };

    const handleOnRowClick = async (t: Target) => {
        const targetWithHistory = await Targets.getTargetWithHistory(t.id, t.deviceId);
        details.show(`Target Details`, "Okay", targetWithHistory, () => { });
    };

    const getDeviceColor = (deviceId: string | undefined) => {
        if (!deviceId) return undefined;
        return facility?.devices?.find(d => d.id === deviceId)?.color ?? "blue"; // default device color is blue
    }

    const handleOnShowTracks = async (target: Target | undefined, show: boolean) => {
        if (!target?.id) return;
        if (show) {
            //If we do not have the history for this, load it...
            const targetWithHistory = await Targets.getTargetWithHistory(target?.id, target?.deviceId);
            if (targetWithHistory) {
                setSelectedTargets((prev) => ({ ...prev, [target.id!]: targetWithHistory }));
            }
        } else {
            //Otherwise they are deselecting this - remove the history
            setSelectedTargets((prev) => (_.omit(prev, target.id!)));
        }
    }
    const facilityShapes = useMemo(() => [...(hideDevices ? [] : facility.devices ?? []), ...facility.screenLines ?? [], ...facility.detectionZones ?? []] as Array<Device | ScreenLine | DetectionZone>, [facility, hideDevices])
    const targetLines = useMemo(() => {
        const polys = [] as Array<[ScreenLine, Device]>;

        Object.keys(selectedTargets).forEach(t => {
            const target = selectedTargets[t];
            const device = facility.devices?.find(d => d.id === target.deviceId);
            if (target.startPosition && target.endPosition && device && coordsHelper) {
                const sl = new ScreenLine();
                let points = [] as Array<Vector2>;
                if (target.history && (target.history?.length ?? 0) >= 2) {
                    points = target.history.map(h => new Vector2({ x: h.position?.x!, y: h.position?.y! }));
                } else {
                    points = [new Vector2({ x: target.endPosition.x, y: target.endPosition.y }), new Vector2({ x: target.startPosition.x, y: target.startPosition.y })];
                }
                sl.points = points;
                sl.id = target.id;
                polys.push([sl, device]);
            }
        });
        return polys;
    }, [facility, selectedTargets, coordsHelper])


    const tableTitle = useMemo(() => {
        const objCount = Object.keys({})?.length;
        const numSelected = Object.keys(selectedTargets)?.length - objCount;
        if (numSelected) {
            return `Recent Targets (${numSelected} of ${allTargets?.length})`;
        }
        return `Recent Targets (${allTargets?.length})`;
    }, [selectedTargets, allTargets]);

    useEffect(() => {
        if (!targetsHelper.isLoading && errors) {
            toastWarning("Error loading targets", errors);
        }
    }, [targetsHelper.isLoading, errors]);
    if (!isLoading && !centerCoords) {
        return <h1>There was an error loading location details please reload the page</h1>
    }
    return (
        <div className="h-screen flex flex-col">
            <div className="h-[50%]">
                {centerCoords &&
                    <OmniGoogleMap center={centerCoords} fitToPolygons={true} noWrap={true}>
                        {facilityShapes.map(mp => (<MapShape facility={facility} key={mp.id} shape={mp} />))}
                        {Object.values(selectedTargets).map((t) => (<TargetMapShape facility={facility} key={t.id} target={t} color={getDeviceColor(t.deviceId)} />))}
                    </OmniGoogleMap>

                }
            </div>
            <div className="flex gap-3 place-items-center">
                <CheckboxInput label="Hide Devices" checked={hideDevices} onChange={() => setHideDevices(!hideDevices)} />
            </div>
            <div className="flex-grow max-h-96">
                <PageContainer title={tableTitle} Icon={Icons.Target} titleRight={
                    <div className="flex gap-x-5">
                        <CheckboxInput label="Select new Targets" disabled={!!dateRange[1]} checked={!dateRange[1] && autoSelect} onChange={x => setAutoSelect(prev => !prev)} />
                        <Select options={timeOptions} onChange={handleOnTimeOptionChange} value={hoursToShow} disabled={targetsHelper.isLoading} />
                        {hoursToShow <= 0 &&
                            <>
                                <DateInput displayTimezone={facility.localTimezoneId} value={dateRange[0]?.toISOString()} onChange={(d) => {
                                    setDateRange((prev) => [d ? zonedTimeToUtc(d, facility.localTimezoneId!) : undefined, prev[1]]);
                                }} />
                                <DateInput displayTimezone={facility.localTimezoneId} value={dateRange[1]?.toISOString()} onChange={(d) => {
                                    setDateRange((prev) => [prev[0], d ? zonedTimeToUtc(d, facility.localTimezoneId!) : undefined]);
                                }} />
                            </>
                        }
                    </div>
                }>
                    <div className="max-h-64 overflow-y-auto">
                        <RecentTargetsTable
                            facility={facility}
                            data={allTargets ?? []}
                            isLoading={targetsHelper.isLoading}
                            onRowClick={handleOnRowClick}
                            showTracksById={t => t?.id ? selectedTargets[t.id] !== undefined : false}
                            onShowTracksChange={handleOnShowTracks} />
                    </div>

                </PageContainer>
            </div>
            {details.renderDialog((val) =>
                <TargetDetailsDialog facility={facility} target={val} onZoomChange={setHideOverflow} />
                , { closeOnOutsideClick: true, showCancelButton: false, className: hideOverflow ? "overflow-hidden max-h-screen w-full max-w-3xl" : undefined })}
        </div>
    );
}