import { LoadingIndicator } from "../loading";
import { FC } from "react";
import { useJsApiLoader } from '@react-google-maps/api';

interface WithGoogleMapsProps {
    children: JSX.Element;
}

export const RequiresGoogleMaps: FC<WithGoogleMapsProps> = ({ children }: WithGoogleMapsProps) => {

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY as string;
    const googleScriptOptions = {
        id: 'google-map-script',
        libraries: ["drawing", "geometry", "core", "marker"],
        googleMapsApiKey: googleMapsApiKey
    };

    const { isLoaded, loadError } = useJsApiLoader(googleScriptOptions as any);
    console.log(isLoaded, loadError);
    if (!isLoaded && !loadError) {
        return <LoadingIndicator />;
    }

    if (loadError) {// this doesn't always work. If you try an invalid api key for example you will not be able to catch the error; only see it on screen/in console. I tried this + window based error handlers + google's js loader + custom script tags + waiting for idle event
        return (<div className="flex items-center justify-center h-screen">
            <div>
                <div className="text-red-500 text-xl font-bold">Error loading Google Maps</div>
                <div className="text-red-400 text-sm mt-2">{loadError.message || "Unknown error"}</div>
            </div>
        </div>);
    }

    return children; // Return the actual child component
}