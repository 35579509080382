import { ClientEndpoint, ClientEndpoints, DetectionZoneDwellEventTrigger, DetectionZonePresenceEventTrigger, Devices, enumKeys, EventTriggerDevice, EventTriggerFacility, Facilities, getTargetTypeDisplayName, ImpendingZoneEntryEventTrigger, MaxServerIntValue, OptionValue, SpeedingEventTrigger, TargetType } from "@app/shared";
import { useEffect, useState } from "react";
import { Model, useFetch, useModel } from "../../../hooks";
import { EditEndpointConfig } from "../EditEndpointConfig/EditEndpointConfig";
import { CheckboxInput, InputRow, NumericInput, Select } from "../inputs";
import { MultiSelect, SimpleOption } from "../inputs/MultiSelect";
import { EventTriggerEditorProps, EventTriggerType } from "./EventTriggerEditor";

type EditEventTriggerProps = {
    model: Model<EventTriggerType>;
    hasScreenline?: boolean;
    hasDetectionZone?: boolean;
} & EventTriggerEditorProps;


const AllTargetTypes = enumKeys(TargetType).sort((a, b) => a.localeCompare(b)).filter(k => k !== "Unknown");

export function EditEventTrigger(props: EditEventTriggerProps) {
    const [detectionZones] = useFetch(() => props.hasDetectionZone ? Facilities.getDetectionZoneOptions(props.trigger?.organizationId, props.facilityId) : Promise.resolve([]));
    const [screenLines] = useFetch(() => props.hasScreenline ? Facilities.getScreenLineOptions(props.trigger?.organizationId, props.facilityId) : Promise.resolve([]));
    const [facilityOptions] = useFetch(() => Facilities.getOptions(props.trigger?.organizationId));
    const [deviceOptions] = useFetch(() => Devices.getOptions(props.trigger?.organizationId, props.facilityId));
    const [endpoints] = useFetch(() => ClientEndpoints.getAll(props.trigger?.organizationId, props.facilityId));
    const [, helper] = useModel(props.model, props.trigger! as Partial<EventTriggerType>);

    const [endpointOptions, setEndpointOptions] = useState<OptionValue[]>();
    const [selectedEndpoint, setSelectedEndpoint] = useState<ClientEndpoint>();

    const onChange = (newTrigger: Partial<EventTriggerType>) => {
        if (props.trigger?.clientEndpointId !== newTrigger.clientEndpointId) {
            newTrigger.endpointConfig = "";
        }
        props.onChange(newTrigger as EventTriggerType);
    };

    useEffect(() => {
        helper.replaceModel(props.trigger as EventTriggerType);
    }, [props.trigger]);

    useEffect(() => {
        setEndpointOptions(endpoints?.map(e => new OptionValue({ label: e.endpointName, value: e.id })));
    }, [endpoints]);

    useEffect(() => {
        setSelectedEndpoint(endpoints?.find(e => e.id === props.trigger?.clientEndpointId));
    }, [props.trigger?.clientEndpointId, endpoints])

    return (
        <>
            {props.hasDetectionZone &&
                <InputRow>
                    <Select label="Detection Zone Filter"
                        emptyOptionText="Any Detection Zone"
                        emptyOptionDisabled={false}
                        optionGroups={detectionZones}
                        {...helper.bindingsFor("detectionZoneId", onChange)}
                    />
                </InputRow>
            }
            {props.hasScreenline &&
                <InputRow>
                    <Select label="Screen Line Filter"
                        emptyOptionText="Any Screen Line"
                        emptyOptionDisabled={false}
                        optionGroups={screenLines}
                        {...helper.bindingsFor("screenLineId", onChange)}
                    /></InputRow>
            }
            <InputRow>
                {

                    facilityOptions &&
                    <MultiSelect label="Facility Filters"
                        options={facilityOptions as SimpleOption[]}
                        value={props.trigger?.eventTriggerFacilities?.map(f => f.facilityId ?? "") ?? []}
                        onChange={(e) => {
                            props.trigger!.eventTriggerFacilities = (e as string[]).map(e => ({ facilityId: e, eventTriggerId: props.trigger?.id } as EventTriggerFacility)) as EventTriggerFacility[];
                            onChange(props.trigger!);
                        }} />
                }
            </InputRow>
            <InputRow>
                {
                    deviceOptions &&
                    <MultiSelect label="Device Filters"
                        options={deviceOptions as SimpleOption[]}
                        value={props.trigger?.eventTriggerDevices?.map(f => f.deviceId ?? "") ?? []}
                        onChange={(e) => {
                            props.trigger!.eventTriggerDevices = (e as string[]).map(e => ({ deviceId: e, eventTriggerId: props.trigger?.id } as EventTriggerDevice)) as EventTriggerDevice[];
                            onChange(props.trigger!);
                        }} />
                }
            </InputRow>
            <InputRow>
                <Select
                    label="Endpoint to Notify"
                    emptyOptionDisabled={false}
                    emptyOptionText="Select Endpoint"
                    options={endpointOptions}
                    {...helper.bindingsFor("clientEndpointId", onChange)}
                />
            </InputRow>
            {props.trigger?.$type === DetectionZoneDwellEventTrigger.$type &&
                <InputRow>
                    <NumericInput allowFloat={false} label="Minimum Dwell Time (s)" {...helper.bindingsFor("minValue", onChange)} />
                </InputRow>
            }
            {props.trigger?.$type === DetectionZonePresenceEventTrigger.$type &&
                <>
                    <InputRow>
                        <NumericInput allowFloat={false} label="Minimum Targets" {...helper.bindingsFor("minValue", onChange)} />
                    </InputRow>
                    <InputRow>
                        <NumericInput allowFloat={false} label="Min Dwell Time (ms)" {...helper.bindingsFor("additionalValue", onChange)} />
                    </InputRow>
                </>
            }
            {props.trigger?.$type === ImpendingZoneEntryEventTrigger.$type &&
                <>
                    <InputRow>
                        <NumericInput label="Minimum time to a collision" {...helper.bindingsFor("minValue", onChange)} debounce={0} />
                    </InputRow>
                    <InputRow>
                        <NumericInput label="Minimum speed" {...helper.bindingsFor("additionalValue", onChange)} debounce={0} />
                    </InputRow>
                </>
            }
            {props.trigger?.$type === SpeedingEventTrigger.$type &&
                <InputRow>
                    <NumericInput label="Minimum Speed" {...helper.bindingsFor("minValue", onChange)} />
                    <NumericInput label="Maximum Speed" {...helper.bindingsFor("maxValue", onChange)} />
                </InputRow>
            }

            <InputRow>
                <CheckboxInput label="Process On Edge Device"
                    {...helper.bindingsFor("processOnDevice", onChange)}
                />
            </InputRow>
            <EditEndpointConfig endpoint={selectedEndpoint} {...helper.bindingsFor("endpointConfig", onChange)} />
            <InputRow>Target Filters:<br />
                <div className="flex flex-row place-items-center gap-2">
                    <label>
                        <input
                            className="border-0 shadow-none w-8"
                            type="checkbox"
                            onChange={(e) => {
                                if (props.trigger == null) return;
                                props.trigger.targetFilter = e.target.checked ? MaxServerIntValue : 0;
                                onChange(props.trigger);
                            }}
                            checked={props.trigger?.targetFilter === MaxServerIntValue} />
                        All Targets
                    </label>
                </div>
                {AllTargetTypes.map((t => (
                    <div key={t} className="flex flex-row place-items-center gap-2">
                        <label>
                            <input
                                className="border-0 shadow-none w-8"
                                type="checkbox"
                                onChange={(e) => {
                                    if (props.trigger?.targetFilter == null) return;
                                    if (e.target.checked) {
                                        props.trigger.targetFilter |= TargetType[t];
                                        onChange(props.trigger);
                                    }
                                    else {
                                        props.trigger.targetFilter ^= TargetType[t];
                                        onChange(props.trigger);
                                    }
                                }}
                                checked={((props.trigger?.targetFilter ?? 0) & TargetType[t]) > 0} />
                            {getTargetTypeDisplayName(t)}
                        </label>
                    </div>
                )))}
            </InputRow>
        </>
    );
}