import { DetectionZone, Facility, ScreenLine } from "@app/shared";
import { useContext, useEffect, useRef, useState } from "react";
import { GooglePolyHandler, ICoordsHelper, MapContext, MapObjArray, createGooglePoly, createVertexCircle, debounce, useFacilityCoordsHelper } from "../../../../helpers";
import { usePolygonScaleAndRotation } from "../../../../hooks";



export type ParkingSpotGroupMapShapeProps = { facility: Facility, spots: Array<DetectionZone>, onUpdate: (spots: Array<DetectionZone>) => void };
/**
 * Represents a custom React component for displaying user information.
 * @param {Facility} facility the location of the spots
 * @param {Array<DetectionZone>} spots the group of parking spots (DetectionZones) to render onto Google map
 * @param {Function} onUpdate a function to be called if an update is made to the group of spots
 * @returns {JSX.Element} A React component that efficiently renders many parking spots at once. Must be rendered inside of Map context
 */
export const ParkingSpotGroupMapShape: React.FC<ParkingSpotGroupMapShapeProps> = ({ facility, spots, onUpdate }) => {
    const [parentMap, setBounds] = useContext(MapContext);
    const mapObjects = useRef([] as MapObjArray);
    const [zonePoly, setZonePoly] = useState<google.maps.Polygon>();
    const [vertexCircles, setVertexCircles] = useState<Array<Array<google.maps.Circle>>>();
    const coordsHelper = useFacilityCoordsHelper(facility);

    useEffect(() => {//create/update google poly for shape      
        if (parentMap && spots?.length) {
            const { polygon: poly, cleanup } = initPoly(spots, parentMap, coordsHelper);
            if (!poly) return
            setZonePoly(poly);
            setBounds(curr => {
                poly?.getPaths().getArray().forEach(pt => pt.getArray().forEach(x => curr.extend(x)));
                return curr;
            })

            return () => {
                cleanup();
                mapObjects.current?.forEach(mo => { mo?.setVisible(false); mo.setMap(null); });
                poly.setMap(null); poly.setVisible(false);
                setZonePoly(undefined);
                mapObjects.current = [];
            }
        }
    }, [parentMap, mapObjects, spots, coordsHelper, setBounds]);

    const debouncedUpdateAndRotate = debounce(() => {
        if (onUpdate && spots) {
            const shapes = zonePoly?.getPaths().getArray().map((path, idx) => {
                const points = path.getArray().map(coordsHelper.fromLatLng);
                const s = points;
                if (s?.length && s[0].x !== s[s.length - 1].x && s[0].y !== s[s.length - 1].y) {
                    s.push(s[0]);
                }
                return Object.assign(new DetectionZone(), { ...spots[idx], points: points });
            });

            onUpdate(shapes!);
        }
    }, 500);

    useEffect(() => {
        if (parentMap) {
            const circles = zonePoly?.getPaths().getArray().map(path => path.getArray().map(pos => createVertexCircle(pos, parentMap, false)));
            setVertexCircles(circles);
            return () => {
                circles?.forEach(poly => poly.forEach(circle => { circle.setMap(null); circle.setVisible(false); }));
                setVertexCircles(undefined);
            }
        }
    }, [zonePoly, parentMap]);

    usePolygonScaleAndRotation(parentMap, zonePoly, () => !!zonePoly, debouncedUpdateAndRotate, () => vertexCircles ?? [], [vertexCircles, zonePoly, parentMap]);

    useEffect(() => {//Handle dragging
        if (zonePoly && parentMap) {
            const dragStop = (e: google.maps.MapMouseEvent) => {
                const newPaths = zonePoly?.getPaths().getArray();
                if (newPaths) {
                    const ns = spots.map((s, idx) => {
                        const points = newPaths[idx].getArray().map(coordsHelper.fromLatLng);
                        if (points[0].x !== points[points.length - 1].x && points[0].y !== points[points.length - 1].y) {
                            points.push(points[0]);
                        }
                        return Object.assign(new DetectionZone(), { ...s, points: points });
                    });
                    onUpdate(ns);
                }
            }
            const dListener = zonePoly.addListener('dragend', dragStop);

            return () => {
                dListener?.remove();
            }
        }
    }, [parentMap, spots, onUpdate, coordsHelper.fromLatLng, zonePoly]);

    return null;
}

function initPoly(shapes: Array<DetectionZone | ScreenLine>, parentMap: google.maps.Map, coordsHelper: ICoordsHelper, color?: string) {
    return createGooglePoly(shapes.map(s => new GooglePolyHandler(s.$type || "", s.points || [])), coordsHelper, parentMap, {
        strokeColor: color,
        strokeOpacity: 0.8,
        fillColor: color,
        clickable: true,
        draggable: true,
        zIndex: 100
    });
}