export interface IOrientationSlim {
	error: number;

	pitch: number;

	$type?: string | undefined;
}
export class OrientationSlim {
	constructor(data?: IOrientationSlim) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	error: number = 0;

	pitch: number = 0;

	static $type: string = "CoreApi.Controllers.OrientationSlim, ";
	$type?: string | undefined = OrientationSlim.$type;
}

