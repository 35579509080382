import { DetectionZone } from "@app/shared";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CLOSESHAPETHRESHOLD, GooglePolyHandler, ICoordsHelper, MapContext, circle, createGooglePoly, createVertexCircle, useFacilityCoordsHelper } from "../../../../helpers";
import { debounce } from "../../../../helpers/debounceFunction";
import { usePolygonScaleAndRotation } from "../../../../hooks";
import { MapShapeProps } from "../MapShape";

export interface DetectionZoneMapShapeProps extends MapShapeProps<DetectionZone> {
    opacity?: number,
    fillColor?: string,
    strokeColor?: string
};

export const DetectionZoneMapShape: React.FC<DetectionZoneMapShapeProps> = ({ facility, shape, isEditable, isSelectable, onUpdate, isSelected, onClick, opacity, fillColor, strokeColor }) => {
    const [parentMap, setBounds] = useContext(MapContext);
    const listeners = useRef([] as Array<google.maps.MapsEventListener>);
    const vertexCircles = useRef([] as Array<google.maps.Circle>);
    const [zonePoly, setZonePoly] = useState<google.maps.Polygon>();
    const shapeCenter = useRef<google.maps.LatLng>();
    const coordsHelper = useFacilityCoordsHelper(facility);

    useEffect(() => {//create/update google poly for shape    
        if (parentMap && shape?.points?.length && coordsHelper) {
            const { polygon: poly, center, label, cleanup } = initPoly(shape, parentMap, coordsHelper, fillColor, strokeColor, opacity);
            if (!poly) return
            setZonePoly(poly);
            shapeCenter.current = center;
            setBounds(curr => {
                poly?.getPath().getArray().forEach(pt => curr.extend(pt));
                return curr;
            });
            return () => {
                cleanup();
                poly.setMap(null); poly.setVisible(false);
                shapeCenter.current = undefined;
                setZonePoly(undefined);
            }
        }
    }, [parentMap, shape, shape?.points, coordsHelper, fillColor, strokeColor, opacity, setBounds]);

    const handleEditClick = (e: google.maps.MapMouseEvent, complete?: boolean) => {
        if (!e.latLng || !shape) { return; }
        const dz = shape;
        const v2 = coordsHelper.fromLatLng(e.latLng);

        if (dz.points?.length) {
            dz.points.push(v2);
        } else {
            dz.points = [v2];
            onUpdate?.(Object.assign(new DetectionZone(), { ...dz }));
            return;
        }
        const firstToLastDistance = v2.getDistanceTo(dz.points[0]);
        if (dz.points.length > 1 && ((firstToLastDistance <= CLOSESHAPETHRESHOLD) || complete)) {
            dz.points[dz.points.length - 1] = { ...dz.points[0] };//fast clamp
            onUpdate?.(Object.assign(new DetectionZone(), { ...dz }));
            return;
        }
        if (zonePoly) {
            const lastMousePos = zonePoly.getPath().pop();
            zonePoly.setPath([...(dz.points.map(coordsHelper.toLatLng) ?? []), lastMousePos])
            onUpdate?.(Object.assign(new DetectionZone(), { ...dz }));
        }
    }
    const memoHandleEditClick = useCallback(handleEditClick, [shape, onUpdate, coordsHelper, zonePoly]);

    const handleMove = (e: google.maps.MapMouseEvent) => {
        if (!zonePoly || !isEditable || !e.latLng || shape?.isComplete()) return;

        const path = zonePoly.getPath().getArray();

        if ((shape?.points?.length !== undefined) && shape.points.length < path.length) {
            path.pop();
        }

        zonePoly.setPath([...path, e.latLng]);
    };
    const memoHandleMove = useCallback(handleMove, [zonePoly, isEditable, shape]);

    const handleVertexDrag = useCallback((d: google.maps.MapMouseEvent, idx: number) => {

        if (d.latLng && zonePoly) {
            const path = zonePoly.getPath();
            path.setAt(idx, d.latLng);
            zonePoly?.setPath(path)
        }
    }, [zonePoly]);

    const handleVertexDragEnd = useCallback((d: google.maps.MapMouseEvent, idx: number) => {
        //unintuitively dragging will change position of multiple points in path so don't use that path to calc new DZ position
        if (d.latLng && zonePoly) {
            const points = [...shape?.points ?? []];
            points[idx] = coordsHelper.fromLatLng(d.latLng);
            const path = zonePoly.getPath();
            path.setAt(idx, d.latLng);
            if (idx === 0) {
                path.setAt(path.getLength() - 1, d.latLng);
                vertexCircles.current[path.getLength() - 1]?.setCenter(d.latLng);
                points[points.length - 1] = points[0];
            } else if (idx === (path.getLength() - 1)) {
                path.setAt(0, d.latLng);
                vertexCircles.current[0]?.setCenter(d.latLng);
                points[0] = points[points.length - 1];
            }
            onUpdate?.(Object.assign(new DetectionZone(), { ...shape, points: points }));
        }
    }, [coordsHelper, onUpdate, zonePoly, shape]);

    const debouncedUpdate = debounce(() => {
        if (onUpdate && zonePoly && shape) {
            const points = zonePoly.getPath().getArray().map(coordsHelper.fromLatLng);
            points.push(points[0]);
            onUpdate?.(Object.assign(new DetectionZone(), { ...shape, points: points }));
        }
    }, 500);

    usePolygonScaleAndRotation(
        parentMap,
        zonePoly,
        () => !!(isEditable && shape && zonePoly && isSelected),
        debouncedUpdate,
        () => [vertexCircles.current],
        [zonePoly, isEditable, shape, isSelected, vertexCircles, parentMap]
    );
    const handlePolyDragStart = () => {
        vertexCircles.current.forEach(vc => vc.setVisible(false));
    }
    const handlePolyDragEnd = useCallback((e: google.maps.PolyMouseEvent) => {
        //updateVertexCircles();
        //vertexCircles.current.forEach(vc => vc.setVisible(false));
        const points = zonePoly?.getPath().getArray().map(coordsHelper.fromLatLng);
        points?.push(points[0]);
        onUpdate?.(Object.assign(new DetectionZone(), { ...shape, points: points }));
    }, [coordsHelper, onUpdate, zonePoly, shape]);



    useEffect(() => { // make selectable
        if (!isSelectable) { return; }
        let selectListener: google.maps.MapsEventListener;
        let selectListener2: google.maps.MapsEventListener;
        const zp = zonePoly;
        if (shape?.isComplete() && onClick && zp) {
            zp.set('clickable', true);
            selectListener = zp.addListener('click', (e: google.maps.MapMouseEvent) => onClick?.(e, Object.assign(new DetectionZone(), { ...shape })));
            selectListener2 = zp.addListener('rightclick', (e: google.maps.MapMouseEvent) => onClick?.(e, Object.assign(new DetectionZone(), { ...shape })));
        }

        return () => {
            zp?.set('clickable', false);
            selectListener?.remove();
            selectListener2?.remove();
        };

    }, [onClick, zonePoly, shape, isSelectable]);

    const createDZVertexCircles = useCallback(() => {
        if (!zonePoly || !parentMap) { throw new Error('map and polygon are required to create circles'); }
        return zonePoly.getPath().getArray().map((pos, idx) => {
            const isClickable = shape?.isComplete() || idx !== 0;
            const posMark = createVertexCircle(pos, parentMap, isClickable);
            if (idx === 0 && !shape?.isComplete()) {
                let isBig = false;
                posMark.set('clickable', true);//won't listen to mouse events otherwise :/
                posMark.addListener('click', (e: google.maps.MapMouseEvent) => memoHandleEditClick(e, true));
                posMark.addListener('mouseover', () => {
                    if (!isBig) posMark.setRadius(posMark.getRadius() + 2); isBig = true;
                });
                posMark.addListener('mouseout', () => { if (isBig) posMark.setRadius(posMark.getRadius() - 2); isBig = false; })
            }

            listeners.current.push(posMark.addListener('dragend', (e: google.maps.MapMouseEvent) => handleVertexDragEnd(e, idx)));
            listeners.current.push(posMark.addListener('drag', (e: google.maps.MapMouseEvent) => handleVertexDrag(e, idx)));

            return posMark;
        });
    }, [handleVertexDrag, handleVertexDragEnd, memoHandleEditClick, parentMap, shape, zonePoly]);

    useEffect(() => {//handle selection
        if (isSelected && zonePoly && shape?.isComplete()) {
            animateCircle(zonePoly, parentMap);
        }
    }, [isSelected, shape, parentMap, zonePoly]);


    useEffect(() => { //editable    
        if (isEditable && parentMap) {
            if (!shape?.isComplete()) {
                listeners.current.push(parentMap.addListener('click', memoHandleEditClick));
                listeners.current.push(parentMap.addListener('mouseout', () => zonePoly?.setPath([...(shape?.points?.map(coordsHelper.toLatLng)) ?? []])));
                listeners.current.push(parentMap.addListener('mousemove', memoHandleMove));
            }

            if (zonePoly) {
                vertexCircles.current = createDZVertexCircles();
                if (shape?.isComplete()) {
                    zonePoly.setDraggable(true);
                    listeners.current.push(zonePoly.addListener('dragstart', handlePolyDragStart));
                    listeners.current.push(zonePoly.addListener('dragend', handlePolyDragEnd));
                }
            }
        }

        return () => {
            listeners.current.forEach(l => l?.remove());
            listeners.current = [];
            vertexCircles.current.forEach(l => { l.setVisible(false); l.setMap(null) });
            vertexCircles.current = [];
            zonePoly?.setDraggable(false);
        }

    }, [isEditable, shape, parentMap, memoHandleMove, memoHandleEditClick, zonePoly, coordsHelper, createDZVertexCircles, handlePolyDragEnd])
    return null;
}


function initPoly(shape: DetectionZone, parentMap: google.maps.Map, coordsHelper: ICoordsHelper, fillColor?: string, strokeColor?: string, opacity?: number) {
    return createGooglePoly([new GooglePolyHandler(shape.$type || "", shape.points || [])], coordsHelper, parentMap, undefined, shape.name);
}

function animateCircle(line: google.maps.Polyline, map?: google.maps.Map) {

    const vertexCircles = line.getPath().getArray().map(pos => {
        return new google.maps.Marker({
            icon: circle("#5900ff"),
            position: pos,
            map: map,
            clickable: false,
            animation: google.maps.Animation.DROP
        });
    });
    setTimeout(() => {
        vertexCircles.forEach(c => {
            c.setVisible(false);
            c.setMap(null);
        });
    }, 1000);
}