import { getWebRelayStateDisplayName, IWebRelayConfig, IWebRelayEndpointConfig, OptionValue, WebRelayClientEndpoint, WebRelayConfig, WebRelayEndpointConfig, WebRelayModel, WebRelayState } from "@app/shared";
import { EnumSelect, Input, InputRow, Select } from "../inputs";
import { EditEndpointConfigProps } from "./EditEndpointConfig";

type EditWebRelayEndpointConfigProps = {
} & EditEndpointConfigProps;

const getNumberArray = (start: number, end: number) => {
    return Array.from({ length: (end - start + 1) }, (_, i) => i + start)
}
const getNumberOptions = (start: number, end: number) => {
    return getNumberArray(start, end).map(i => new OptionValue({ label: i + "", value: i + "" }));
}
const biuNumOptions = getNumberOptions(1, 4);
const numDetectorOptions = getNumberOptions(1, 16);

const getNumRelayOptions = (model: WebRelayModel | undefined) => {
    if (model == WebRelayModel.Quad_400_Series || model == WebRelayModel.Quad_Original)
        return getNumberOptions(1, 4);

    return getNumberOptions(1, 1);
};

export const EditWebRelayEndpointConfig: React.FC<EditWebRelayEndpointConfigProps> = (props) => {

    const config = new WebRelayEndpointConfig(JSON.parse((props.value?.length ?? 0) < 1 ? "{}" : props.value!) as IWebRelayEndpointConfig)

    const updateParent = () => {
        props.onChange(JSON.stringify(config))
    };

    const onDetectorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newCount = parseInt(e.target.value);
        if ((config.relays?.length ?? 0) < 1) {
            config.relays = [new WebRelayConfig({ relay: 1 } as IWebRelayConfig)];
        }

        if (config.relays!.length > newCount) {
            config.relays = config.relays?.splice(0, newCount);
        } else if (config.relays!.length < newCount) {
            config.relays = [...config.relays!, ...getNumberArray(config.relays!.length + 1, newCount).map(i => new WebRelayConfig({ relay: i } as IWebRelayConfig))];
        }
        updateParent();
    }

    const relayModel = (props.endpoint as WebRelayClientEndpoint)?.model;

    return (<>
        <InputRow><Select label="Number of Relays" onChange={onDetectorChange} value={config.relays?.length ?? 0} options={getNumRelayOptions(relayModel)} /></InputRow>
        {
            config.relays?.map((d, i) => <InputRow key={i + "-" + d}>
                <div className="grid grid-cols-3 gap-5">
                    <div><Input label="Relay#" value={d.relay} onChange={(e) => { d.relay = parseInt(e.target.value); updateParent(); }}></Input></div>
                    <div><EnumSelect nameToDisplayConverter={getWebRelayStateDisplayName} enumType={WebRelayState} label="State to Send" value={d.stateToSend} onChange={(e) => { d.stateToSend = e as WebRelayState; updateParent(); }} /></div>
                    {d.stateToSend == WebRelayState.PulseOrReboot &&
                        <div><Input label="Pulse Time" value={d.pulseTime} onChange={(e) => { d.pulseTime = parseFloat(e.target.value); updateParent(); }}></Input></div>
                    }
                    <div className="col-span-2"><Input label="Evaluation" value={d.expression ?? ""} onChange={(e) => { d.expression = e.target.value; updateParent(); }}></Input></div>
                </div>
            </InputRow>)
        }
    </>
    );
}