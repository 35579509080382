import { DetectionZone, Facility, LaneLine, ScreenLine } from "@app/shared";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GooglePolyHandler, ICoordsHelper, MapContext, MapObjArray, createGooglePoly, useFacilityCoordsHelper } from "../../../../helpers";

export type GroupMapShapeProps = { facility: Facility, color?: string, shapes: Array<DetectionZone | ScreenLine | LaneLine>, isSelected?: boolean, isEditable?: boolean, onClick?: (shapes: Array<DetectionZone | ScreenLine | LaneLine>) => void };
export const GroupMapShape: React.FC<GroupMapShapeProps> = ({ facility, shapes, isEditable, onClick, color }) => {
    const [parentMap, setBounds] = useContext(MapContext);
    const mapObjects = useRef([] as MapObjArray);
    const listeners = useRef([] as Array<google.maps.MapsEventListener>);

    const [zonePolygon, setZonePolygon] = useState<google.maps.Polygon>();
    const [zonePolylines, setZonePolylines] = useState<Array<google.maps.Polyline>>();
    const originalCenter = useRef<google.maps.LatLng>();
    const coordsHelper = useFacilityCoordsHelper(facility);

    useEffect(() => {//create/update google poly for shape      
        if (parentMap && shapes?.length) {
            // Specify whether to create a polyline based on your requirement

            const { polygon, polylines, center, cleanup } = initPoly(
                shapes,
                parentMap,
                coordsHelper,
                color,
            );

            // Store reference to either polygon or polyline
            originalCenter.current = center;
            if (polygon) {
                setZonePolygon(polygon)
            }
            if (polylines) {
                setZonePolylines(polylines)
            }

            setBounds(curr => {
                // Handle both polygon and polyline paths
                if (polygon) {
                    polygon.getPath().getArray().forEach(pt => curr.extend(pt));
                }
                polylines.forEach(p => p.getPath().getArray().forEach(pt => curr.extend(pt)))
                return curr;
            });

            return () => {
                cleanup();
                mapObjects.current?.forEach(mo => { mo?.setVisible(false); mo.setMap(null); });
                mapObjects.current = [];
                polygon?.setMap(null);
                polygon?.setVisible(false);
                polylines.forEach(p => {
                    p.setMap(null)
                    p.setVisible(false)
                })
                setZonePolylines(undefined);
                setZonePolygon(undefined);
            }
        }
    }, [parentMap, mapObjects, shapes, color, coordsHelper, setBounds]);

    const createPaths = (shapes: Array<ScreenLine | DetectionZone | LaneLine>, xDelta: number, yDelta: number) => {
        return shapes.map(shape => shape.points?.map(coordsHelper.toLatLng)).map(path => path!.map(pt => new google.maps.LatLng({ lng: pt.lng() + xDelta, lat: pt.lat() + yDelta })))
    }

    const handleMove = useCallback((e: google.maps.MapMouseEvent) => {
        if ((zonePolygon || zonePolylines?.length) && originalCenter.current && e.latLng) {
            const xDelta = e.latLng.lng() - originalCenter.current.lng();
            const yDelta = e.latLng.lat() - originalCenter.current.lat();

            const DZoneAndScreenLinesPaths = createPaths(shapes.filter(s => s.$type === ScreenLine.$type || s.$type === DetectionZone.$type), xDelta, yDelta)
            const LanePaths = createPaths(shapes.filter(s => s.$type === LaneLine.$type), xDelta, yDelta)

            zonePolygon?.setMap(parentMap ?? null);
            zonePolygon?.setVisible(true);
            zonePolylines?.forEach(z => {
                z?.setMap(parentMap ?? null);
                z?.setVisible(true);
            })
            if (zonePolygon) {
                zonePolygon.setPaths(DZoneAndScreenLinesPaths)
            }
            if (zonePolylines?.length) {
                zonePolylines.forEach((p, i) => p.setPath(LanePaths[i]))
            }
        }
    }, [parentMap, coordsHelper, shapes, zonePolylines, zonePolygon]);

    const handleClick = useCallback((e: google.maps.MapMouseEvent) => {
        if (originalCenter.current && e.latLng) {
            const xDelta = e.latLng.lng() - originalCenter.current.lng();
            const yDelta = e.latLng.lat() - originalCenter.current.lat();

            const updatedShapes = shapes.map(shape => {
                if (!shape.points) { return shape; }
                const ns = shape.$type === DetectionZone.$type ? Object.assign(new DetectionZone(), { ...shape }) :
                    shape.$type === ScreenLine.$type ? Object.assign(new ScreenLine(), { ...shape }) :
                        Object.assign(new LaneLine(), { ...shape });
                ns.points = shape.points
                    .map(coordsHelper.toLatLng)
                    .map(pt => new google.maps.LatLng({ lng: pt.lng() + xDelta, lat: pt.lat() + yDelta }))
                    .map(coordsHelper.fromLatLng);
                return ns;
            });
            onClick?.(updatedShapes);
        }
    }, [shapes, coordsHelper, onClick]);

    useEffect(() => {
        listeners.current.forEach(l => l?.remove());
        listeners.current = [];

        if (!isEditable) {
            return;
        }
        if ((zonePolygon || zonePolylines?.length) && parentMap) {
            listeners.current.push(parentMap.addListener('mouseout', () => {
                zonePolylines?.forEach(p => p.setVisible(false))
                zonePolygon?.setVisible(false)
            }));
            listeners.current.push(parentMap.addListener('mousein', () => {
                zonePolylines?.forEach(p => p.setVisible(true))
                zonePolygon?.setVisible(true)
            }));
            listeners.current.push(parentMap.addListener('mousemove', handleMove));
            listeners.current.push(parentMap.addListener('click', handleClick));

            return () => {
                listeners.current.forEach(l => l?.remove());
                listeners.current = [];
            }
        }
    }, [zonePolygon, zonePolylines, parentMap, isEditable, handleClick, handleMove]);

    return null;
}

function initPoly(shapes: Array<DetectionZone | ScreenLine | LaneLine>, parentMap: google.maps.Map, coordsHelper: ICoordsHelper, color?: string,) {
    return createGooglePoly(shapes.map(s => new GooglePolyHandler(s.$type || "", s.points ?? [])), coordsHelper, parentMap, {
        visible: false,
        strokeOpacity: 0.8,
        draggable: true,
        zIndex: 100
    });
}