import { DataInfoCard, Icons } from "../../shared";
import { useLocalStorage } from "react-use";
import { DeviceErrorsTable } from "./DeviceErrorsTable";
import { ServerErrorsTable } from "./ServerErrorsTables";

export const ErrorsView: React.FC = () => {
    const [errorSource, setErrorSource] = useLocalStorage<"device" | "server">("ExceptionsViewLastSelection", "server");
    const selectedCardClasses = "border border-separators-page p-5 flex flex-1 gap-3 min-w-min flex-grow shadow-sm border-brand-primary text-brand-primary ring-2 ring-brand-primary shadow-md transition-all";
    const cardClasses = "border border-separators-page p-5 flex flex-1 gap-3 min-w-min flex-grow hover:shadow-lg transition-all"

    return (
        <>
            <div className="flex justify-center">
                <div className="flex gap-4 w-1/4 m-6">
                    <DataInfoCard Icon={Icons.Details} onClick={() => setErrorSource("device")} className={errorSource === "device" ? selectedCardClasses : cardClasses} hoverText="Recent errors reported by devices. Devices with older firmware versions and those with connectivity issues may not report errors. Check logs on device for " title="Device Errors"></DataInfoCard>
                    <DataInfoCard Icon={Icons.Details} onClick={() => setErrorSource("server")} className={errorSource === "server" ? selectedCardClasses : cardClasses} hoverText="Recent errors from server log since app last started. See Cloudwatch for context (search with trace id) and older entries" title="Server Errors" ></DataInfoCard>
                </div>
            </div>
            {errorSource === "device" && <DeviceErrorsTable />}
            {errorSource === "server" && <ServerErrorsTable />}
        </>
    );
}