import { EmailClientEndpoint } from "@app/shared";
import { useModel } from "../../../hooks";
import { CheckboxInput, Input, InputRow, PasswordInput, TextArea } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";
import { HoverTooltip } from "../HoverTooltip";

type EditEmailClientEndpointProps = {
} & ClientEndpointEditorProps;

export const EditEmailClientEndpoint: React.FC<EditEmailClientEndpointProps> = (props) => {
    const [model, helper] = useModel(EmailClientEndpoint, props.endpoint as EmailClientEndpoint);
    const onChange = (newEndpoint: Partial<EmailClientEndpoint>) => {
        props.onChange(newEndpoint as EmailClientEndpoint);
    };
    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
            <InputRow><HoverTooltip offset={[400, -60]} hoverable={<TextArea label="Email Address(es)" rows={5} {...helper.bindingsFor("emailAddress", onChange)} />}>Email address or comma separated list of email addresses</HoverTooltip></InputRow>
            <InputRow><CheckboxInput label="Use Custom Smtp Server?" {...helper.bindingsFor("useCustomSmtpServer", onChange)} /></InputRow>
            {model?.useCustomSmtpServer && <>
                <InputRow><Input label="FromEmail" {...helper.bindingsFor("fromEmail", onChange)} /></InputRow>
                <InputRow><Input label="Server" {...helper.bindingsFor("server", onChange)} /></InputRow>
                <InputRow><Input label="Username" {...helper.bindingsFor("username", onChange)} /></InputRow>
                <InputRow><Input label="Port" {...helper.bindingsFor("port", onChange)} /></InputRow>
                <InputRow><PasswordInput label="Password" {...helper.bindingsFor("password", onChange)} /></InputRow>
                <InputRow><CheckboxInput label="UseSsl" {...helper.bindingsFor("useSsl", onChange)} /></InputRow>
            </>}
        </>
    );
}