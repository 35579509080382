import { DetailedHTMLProps, FunctionComponent, TextareaHTMLAttributes } from "react";
import { InputProps } from "./inputTypes";
import { ValidatedInputGroup } from "./ValidatedInputGroup";
import { InputLabel } from "./InputLabel";


export const TextArea: FunctionComponent<InputProps<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>>> = (props) => {
    const { fieldName, validationError, validateModel, className = "w-full", ...inputProps } = props;

    return (
        <ValidatedInputGroup fieldName={fieldName} validate={validateModel} validationError={validationError}>
            {({ label, handleBlur }) =>
                <>
                    {props.label && <InputLabel>{props.label}</InputLabel>}
                    <textarea                    
                        className={className}
                        placeholder={label}
                        onBlur={handleBlur}
                        {...inputProps}
                    />
                </>
            }
        </ValidatedInputGroup>
    );
}

