// export const extLightThemeColors = [
//     "#1f77b4", // Blue
//     "#ff7f0e", // Orange
//     "#2ca02c", // Green
//     "#d62728", // Red
//     "#9467bd", // Purple
//     "#8c564b", // Brown
//     "#e377c2", // Pink
//     "#7f7f7f", // Gray
//     "#bcbd22", // Yellow-Green
//     "#17becf", // Cyan
//     "#ffbb78", // Light Orange
//     "#98df8a"  // Light Green
// ];

// export const extLightThemeColors = [

//     "#DC3912", // Rich Red
//     "#FF9900", // Vibrant Orange
//     "#990099", // Royal Purple
//     "#3366CC", // Deep Blue
//     "#109618", // Lush Green
//     "#3B3EAC", // Deep Indigo
//     "#0099C6", // Teal Blue
//     "#DD4477", // Soft Rose
//     "#66AA00", // Olive Green
//     "#B82E2E", // Dark Maroon
//     "#316395", // Muted Blue
//     "#994499"  // Muted Purple
// ];

//export const extLightThemeColors = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];
export const extLightThemeColors = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];