import { useAuth } from "@app/shared";
import { PropsWithChildren, useMemo } from "react";
import { useSessionStorage } from "react-use";


export const AdminNagScreen = (props: PropsWithChildren) => {
    const [nagHasPrompted, setNagHasPrompted] = useSessionStorage('nagHasPrompted', false);
    const auth = useAuth();

    const showAdminNagBorder = useMemo(() => {
        if (auth.isLoading) { return false; }
        if (auth.userClaims?.find(uc => uc.claims.find(c => c === 5)) === null) { return false; }
        if (auth.user?.email !== 'admin@omnisightusa.com') { return false; }
        if (auth.user) {

            if (!nagHasPrompted && process.env.NODE_ENV === 'production') {
                alert('Please log in as a named user instead of the admin account');
                setNagHasPrompted(true);
            }
            return true;
        }
        return false;
    }, [auth.userClaims, auth.user, auth.isLoading, nagHasPrompted, setNagHasPrompted]);
    return (
        <>
            {showAdminNagBorder &&
                <div className="border-4 border-[#ff0000] w-screen h-screen">
                    {props.children}
                </div>
            }
            {!showAdminNagBorder && props.children}

            {/* <span className="absolute top-0 left-1/2 -translate-x-1/2 text-xs text-[#000000]">
                    Log in as named user
                </span>

                <span className="absolute bottom-0 left-1/2 -translate-x-1/2 text-xs text-[#000000]">
                    Log in as named user
                </span>

                <span className="absolute left-0 top-1/2 -translate-y-1/2 -rotate-90 text-xs text-[#000000]">
                    Log in as named user
                </span>

                <span className="absolute right-0 top-1/2 -translate-y-1/2 rotate-90 text-xs text-[#000000]">
                    Log in as named user
                </span> */}

        </>
    );
}